import React, { useEffect, useState } from 'react';
import ppt1 from '../assets2/format/ppt1.png';
import ppt1m from '../assets2/format/ppt1m.png';

const PPT1: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".ppt1-wrap");
      const section1 = document.querySelector(".ppt1-cwrap");

      if (!section) return;
      if (!section1) return;

      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const threshold = windowHeight / 1.3;
      if (rect.top <= threshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      const rect1 = section1.getBoundingClientRect();
      const windowHeight1 = window.innerHeight;
      const threshold1 = windowHeight1 / 1.3;
      if (rect1.top <= threshold1) {
        setIsVisible2(true);
      } else {
        setIsVisible2(false);
      }

    };
    document.getElementById('wrapper')?.addEventListener("scroll", handleScroll);
    return () => {
      document.getElementById('wrapper')?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (

    <div className='ppt1-wrap'>
      <div className="ftitlebox" style={{ marginBottom: '2rem', paddingTop: '2rem' }}>
        <p className="subtit">THE ULTIMATE HEALTH TIPS <br className="mo-only" /> FOR YOUR BODY</p>
        <div className="subt-wrap">
          <div className="subt-ani">
            <p className={isVisible ? "subt-act" : "subt-inact"}>
              <span className="subt-con">" 최적화된 맞춤형 솔루션을 제공합니다. "</span>
            </p>
          </div>
        </div>
      </div>

      <img className='ppt1-img pc-only' src={ppt1} alt="" />
      <img className='ppt1-img mo-only' src={ppt1m} alt="" />
      <div className={`ppt1-cwrap ${isVisible2 ? 'ppt1-opacity-y' : 'ppt1-opacity-n'}  `}>
        {/* <p>
          <span className={`ppt1-fir-txt orange `}>
            "당신의 고민은 무엇인가요?"
          </span>
        </p>
        <p className='ppt1-sec-txt'>
          당신이 <span className='ppt1-sec-txt orange'>진정 원하는것</span>은 무엇입니까?
        </p> */}
        <p className={'ppt1-fir-txt'} style={{color: 'white'}}>
          Ultimately,<br/>
          YonnSKin-Wellness
        </p>
        <p className={'ppt1-sec-txt'} style={{color: 'white'}}>
          같은 레슨은 진행하는데 왜 결과가 다르냐고 묻습니다.<br/>
          우리의 목표는 오직 하나, 피부를 건강하고 아름답게 하는 것.<br/>
          당연한 것에서 답을 찾는 사람들.<br/>
          건강은 결국 YoonSkin-Wellness 입니다
        </p>
      </div>
    </div>
  );
};

export default PPT1;