import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dummy from "../../Dummy";
import btnMap from "../../assets2/btn_map.png"
import FadeSlider from "../../FadeSlider";
import CommunitySlide1 from "../../CommunitySlide1";
import CommunitySlide2 from "../../CommunitySlide2";
import BoardAPI from '../../../apis/BoardAPI';
import { BoardItem, CommunityParams, Page, Role, initCommunityParams } from '../../../types/types';
import NoContent from '../../NoContent';
import Pagination from '../../Pagenation';
import errorImg from '../../../assets/errorImg.jpg'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/RootReducer';
import useAuthorization from '../../../hooks/useAuthorization';



const Community = () => {

  const navigate = useNavigate();
  const slides = [CommunitySlide1];
  const userState = useSelector((state: RootState) => state.user);
  const [handleAuth, setHandleAuth] = useState(useAuthorization());


  //list search
  const [page, setPage] = useState(1);
  const [searchType, setSearchType] = useState('all');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [CommunityList, setCommunityList] = useState<Page<BoardItem> | null>(null);
  const [params, setParams] = useState(initCommunityParams);

  //searcKeyword
  const [isFocused, setIsFocused] = useState(false);
  const wrapper = document.getElementById('wrapper');

  useEffect(() => { load() }, []);

  useEffect(() => {
    setHandleAuth(userState.user.roles === Role.ADMIN)
  }, [userState]);


  useEffect(() => {
    setParams(prevParams => ({ ...prevParams, page: page, searchType: searchType, searchKeyword: searchKeyword }));
  }, [page, searchType, searchKeyword]);

  //enter 검색
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      load();
      // window?.scrollTo({ top: 0, behavior: 'smooth' });//화면상단 자동스크롤
    }
  };

  const goSaveForm = (e: any) => {
    if (handleAuth) navigate('/COMMUNITY/save');
  }


  //Page리스트 변경
  const pageChange = (page: number) => {
    const viewportHeight = window.innerHeight;
    wrapper?.scrollTo({ top: viewportHeight, behavior: 'smooth' });
    setPage(page);
    load(page);
  }

  const itemClick = (item: BoardItem, event?: React.MouseEvent<HTMLAnchorElement>) => {
    wrapper?.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/COMMUNITY/' + item.id)
  };


  //목록조회 API
  const load = (pageNumber?: number) => {
    const updatedParams = {
      ...params,
      page: pageNumber !== undefined ? pageNumber : params.page
    };

    console.log('sendParams: ', updatedParams);

    BoardAPI.boardList(updatedParams)
      .then((res) => {
        console.log('response: ', res);
        setCommunityList(res.data);
      })
      .catch((error) => {
        console.log('boardAPI-E: ', error.response?.data?.message);
        setCommunityList(null);
      });
  };


  return (
    <div>
      <FadeSlider components={slides} />
      <div>

        <div className="search-wrap">
          <div className="comu-save-box mb-3">
            {(handleAuth && userState.user.roles === Role.ADMIN) ? <button type="button" className="btn btn-primary" onClick={goSaveForm}>등록하기</button> : ''}
          </div>
          <div className="search-box">
            <select
              id='hi'
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className={`${isFocused ? 'focused' : ''} search-box-select`}
              value={searchType} onChange={(e) => setSearchType(e.target.value)}>
              <option value="all">전체</option>
              <option value="title">제목</option>
              <option value="content">내용</option>
            </select>
            <div className='search-box-inner'>
              <input
                className='search-box-input'
                type="text"
                placeholder="검색어를 입력하세요"
                value={searchKeyword}
                onKeyDown={handleKeyDown}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <button className='search-box-btn' onClick={() => load()}  >검색</button>
            </div>
          </div>
        </div>

        {CommunityList && CommunityList.content.length > 0 ?
          <>
            {/* <div className="commu-list list-container">
              {CommunityList.content.map((item, index) => (
                <div key={index} className="commu-item">
                  <a onClick={(event) => itemClick(item, event)} className="item-card">
                    <div className="row">
                      <div className="col-6">
                        {item.boardFileList.length > 0 ?
                          <img
                            className="item-image2"
                            src={`/api/file/getFile?savedPath=${item.boardFileList[0].filePath}`}
                          />
                          :
                          <img
                            className="item-image2"
                            src={errorImg} />
                        }
                      </div>
                      <div className="col-6">
                        <article>
                          <h1 className='item-title-h1'>{item.title}</h1>
                          <p className='item-text'>{item.content}</p>
                        </article>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div> */}
            <div className="commu-list list-container">
              {CommunityList.content.map((item, index) => (
                <div key={index} className="commu-item" style={{ maxWidth: '100%', maxHeight: '100%', aspectRatio: '5/5' }}>
                  <a onClick={(event) => itemClick(item, event)} >
                    <div className="row" style={{ margin: 'inherit', height: '70%' }}>
                      {item.boardFileList.length > 0 ?
                        <img src={`/api/file/getFile?savedPath=${item.boardFileList[0].filePath}`} alt=""
                          style={{ padding: '0', maxHeight: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                        :
                        <img src={errorImg} alt=""
                          style={{ padding: '0', maxHeight: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                      }
                    </div>
                    <div className="row" style={{ padding: '10px', margin: 'inherit', height: '30%', borderTop: '1px solid #d3d3d3' }}>
                      <article style={{ padding: '0' }}>
                        <h1 className='item-title-h1' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '2rem' }}>{item.title}</h1>
                        <p className='item-text'>{item.content}</p>
                      </article>
                    </div>
                  </a>
                </div>
              ))}
            </div>

            <Pagination
              page={page}
              totalPageCount={CommunityList.totalPages}
              onPageChange={pageChange}
            />
          </>
        : <NoContent />}

        {/* <div className="commu-list list-container">
          <div className="item">
            <a href="#" className="item-card">
              <div className="item-image" style={{ backgroundImage: 'url(https://media.istockphoto.com/photos/fried-egg-with-bacon-in-a-white-plate-with-fried-pieces-of-bread-picture-id1079584712?k=20&m=1079584712&s=170667a&w=0&h=JuK4Je9_SHk3jZXYeifSVRvutE3CKih-_iRfH8T0Kb4=)' }}>
              </div>
              <article>
                <h1>아메리카 브런치 세트</h1>
                <p>정성스럽게 구운 팬케익과 프렌치 토스트에 각종 사이드 디쉬, 그리고 샐러드를 곁들인 푸짐한 정통 미국식 브런치</p>
                <div className="item-info">
                  <span><img src={btnMap} alt="" /></span>
                  <span>16,500원</span>
                </div>
              </article>
            </a>
          </div>
          <div className="item">
            <a href="#" className="item-card">
              <div className="item-image" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1596649299486-4cdea56fd59d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGJ1cmdlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60)' }}>
              </div>
              <article>
                <h1>트리플 머쉬룸 더블 버거</h1>
                <p>육즙 가득한 수제 버거패티와 트러플로 만든 특제 소스로 즐기는 프리미엄 더블 치즈 버거</p>
                <div className="item-info">
                  <span><img src={btnMap} alt="" /></span>
                  <span>16,500원</span>
                </div>
              </article>
            </a>
          </div>
          <div className="item">
            <a href="#" className="item-card">
              <div className="item-image" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1544025162-d76694265947?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80)' }}></div>
              <article>
                <h1>로스티드 립&토마토</h1>
                <p>
                  특제 허니갈릭 페스토를 얹어 통마늘과 함께 구워낸 취향저격 립
                  스테이크
                </p>
                <div className="item-info">
                  <span><img src={btnMap} alt="" /></span>
                  <span>31,000원</span>
                </div>
              </article>
            </a>
          </div>
          <div className="item">
            <a href="#" className="item-card">
              <div className="item-image" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1540420773420-3366772f4999?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c2FsYWR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60)' }}></div>
              <article>
                <h1>리코타 치즈 샐러드</h1>
                <p>매장에서 직접 끓여서 만든 수제 리코타치즈와 제철 과일 그리고 호밀빵</p>
                <div className="item-info">
                  <span><img src={btnMap} alt="" /></span>
                  <span>Kezz Bracey</span>
                </div>
              </article>
            </a>
          </div>
          <div className="item">
            <a href="#" className="item-card">
              <div className="item-image" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1593253500499-50e579a5733f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA1fHxwYXN0YXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60)' }}></div>
              <article>
                <h1>명란 오일 파스타</h1>
                <p>각종 해산물과 마늘 그리고 신선한 명란알을 넣은 시그니처 오일 파스타</p>
                <div className="item-info">
                  <span><img src={btnMap} alt="" /></span>
                  <span>14,500원</span>
                </div>
              </article>
            </a>
          </div>
          <div className="item">
            <a href="#" className="item-card">
              <div className="item-image" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1587116861219-230ac19df971?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fG1lYWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60)' }}></div>
              <article>
                <h1>스트립 관자</h1>
                <p>관자살의 부드러운 식감을 느낄 수 있는 샐러드</p>
                <div className="item-info">
                  <span><img src={btnMap} alt="" /></span>
                  <span>17,500원</span>
                </div>
              </article>
            </a>
          </div>
          <div className="item">
            <a href="#" className="item-card">
              <div className="item-image" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1587116861219-230ac19df971?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fG1lYWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60)' }}></div>
              <article>
                <h1>딸기 케이크</h1>
                <p>진한 우유 생크림과 딸기가 조화를 이룬 케이크</p>
                <div className="item-info">
                  <span><img src={btnMap} alt="" /></span>
                  <span>26,000원</span>
                </div>
              </article>
            </a>
          </div>
        </div> */}

      </div>
    </div>
  );
}


export default Community;