import Ask from "./AskSave";
import AskList from "./AskList";

const AskWrap = ({ isAdmin }: { isAdmin: boolean }) => {

    if (isAdmin) {
      return <AskList />;
    } else {
      return <Ask />;
    }

}

export default AskWrap;
