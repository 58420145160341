import React, { useEffect, useState } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from './redux/reducers/RootReducer';
import { fetchUser } from './redux/actions/userAsyncsActions';
import ProtectedRoute from './components/ProtectedRoute';
/** common */
import WindowSizeDisplay from './components/WindowSizeDisplay';
import Header from './components/Header';
import SideBar from './components/SideBar';
import Footer from './components/Footer';
import Main from './components/Main';

/** pages */
import Home from './components/pages/Home';
import About from './components/pages/About';

import Introduce from './components/pages/introduce/Introduce';

import Business from './components/pages/business/Business';

import Product from './components/pages/Product';
import ProductStore from './components/pages/ProductStore';

import Ask from './components/pages/ask/AskSave';
import AskWrap from './components/pages/ask/AskWrap';
import AskList from './components/pages/ask/AskList';
import AskDetail from './components/pages/ask/AskDetail';

import Board from './components/pages/Board';
import Community from './components/pages/community/Community';
import CommunitySaveForm from './components/pages/community/CommunitySaveForm';
import CommunityDetail from './components/pages/community/CommunityDetail';
import CommunityEditForm from './components/pages/community/CommunityEditForm';
import Reels from './components/pages/Reels';

// import Join from './components/pages/Join';
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';
import Join2 from './components/pages/Join2';
import Login2 from './components/pages/Login2';
import KakaoLogout from './components/pages/KaKaoLogout';
import Preference from './components/pages/Preference';
import MyPage from './components/pages/MyPage';
import { Role } from './types/types';


type LoadState =
  | "SUCCESS"
  | "LOADING"

const SUCCESS = "SUCCESS"
const LOADING = "LOADING"

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //mobile side bar open
  const [isOpen, setIsOpen] = useState(false);

  //초기 회원정보 조회
  useEffect(() => {
    dispatch(fetchUser() as any);
  }, []);

  const userState = useSelector((state: RootState) => state.user);

  const hamClick = () => { setIsOpen(!isOpen) }

  // const handleTabChange = (tab: TopNavTab) => {
  //   switch (tab) {
  //     case "INTRODUCE":
  //       navigate("/about", { replace: false });
  //       break;
  //     case "BUSINESS":
  //       navigate("/product", { replace: false });
  //       break;
  //     case "PRODUCT":
  //       navigate("/board", { replace: false });
  //       break;
  //     case "COMMUNITY":
  //       navigate("/ask", { replace: false });
  //       break;
  //     case "menu_5":
  //       navigate("/preference", { replace: false });
  //       break;
  //     default:
  //       navigate("/", { replace: false });
  //       break;
  //   }
  // };
  //route
  const routes = useRoutes([
    /** menu */
    { path: "*", element: <Main />, },
    { path: "/home", element: <Main />, },
    { path: "/INTRODUCE", element: <Introduce />, },
    
    { path: "/BUSINESS", element: <Business />, },

    { path: "/PRODUCT", element: <Product />, },
    { path: "/PRODUCT/store", element: <ProductStore />, },

    { path: "/COMMUNITY", element: <Community />, },
    { path: "/COMMUNITY/:id", element: <CommunityDetail />, },
    { path: "/COMMUNITY/save", element: userState.dataLoad && (<ProtectedRoute ><CommunitySaveForm /></ProtectedRoute>), },
    { path: "/COMMUNITY/:id/edit", element: userState.dataLoad && (<ProtectedRoute ><CommunityEditForm /></ProtectedRoute>), },
    // { path: "/COMMUNITY/save", element: userState.dataLoad && (<CommunitySaveForm />), },
    // { path: "/COMMUNITY/:id/edit", element: userState.dataLoad && (<CommunityEditForm />), },

    { path: "/reels", element: <Reels />, },

    { path: "/ask", element: <AskWrap isAdmin={userState.user.roles === Role.ADMIN}  />, },
    { path: "/ask/:id", element: <AskDetail />, },
    { path: "/askList", element: <AskList />, },
    { path: "/askSave", element: <Ask />, },

    { path: "/preference", element: userState.dataLoad && (<ProtectedRoute ><Preference /></ProtectedRoute>), },
    { path: "/board", element: userState.dataLoad && (<ProtectedRoute ><Board /></ProtectedRoute>), },
    
    { path: "/join", element: <Login p_rightPanelActive={true}/>, },
    { path: "/login", element: <Login p_rightPanelActive={false} />, },
    { path: "/logout", element: <Logout />, },
    // { path: "/login", element: <Login2 />, },
    // { path: "/join", element: <Join2 />, },
    { path: "/myPage", element: userState.dataLoad && (<ProtectedRoute ><MyPage /></ProtectedRoute>), },
    { path: "/kakaoLogout", element: <KakaoLogout />, },
  ]);

  return (
    <div id="wrapper" className="main_wrapper">
      <WindowSizeDisplay />
      <Header hamClick={hamClick} userState={userState} />
      <SideBar isOpen={isOpen} hamClick={hamClick} isLogin={userState.isLogin} />
      {routes}
      <Footer />
    </div>
  );
}

export default App;
