import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/RootReducer";
import { useNavigate } from "react-router-dom";
import { ASKCATEGORY, AskItem, ASKSTATUS, BoardItem, initAskParams, initCommunityParams, JoinForm, Page } from "../../types/types";
import FadeSlider2 from "../FadeSlider2";
import VisualSlide from "../VisualSlide";
import Popup from "../popups/Popup";
import person from '../../assets2/bg/person-1824147_640.webp'
import errorImg from '../../assets/errorImg.jpg'
import Pagination from "../Pagenation";

import BoardAPI from "../../apis/BoardAPI";
import AskAPI from "../../apis/AskAPI";
import { fetchUser, update, quit } from "../../redux/actions/userAsyncsActions";


const MyPage = () => {

  const slides = [VisualSlide];
  const SLIDE_TEXT = "MyPage";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user);
  const wrapper = document.getElementById('wrapper');

  //community page 
  const [communityList, setCommunityList] = useState<Page<BoardItem> | null>(null);
  const [communityParams, setCommunityParams] = useState(initCommunityParams);
  // const [communityPage, setCommunityPage] = useState(1);//필요없음

  //askList
  const [askList, setAskList] = useState<Page<AskItem> | null>(null);
  const [askParams, setAskParams] = useState(initAskParams);
  const [askPage, setAskpage] = useState(1);

  //Popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const openPopup = () => setIsPopupOpen(true)
  //팝업닫기 state 초기화 해줘야함
  const closePopup = () => {
    reset()
    joinClearErrors()
    setIsCameraOpen(false)
    setIsPopupOpen(false)
    setImagePreview(null)
  }

  //회원탈퇴 팝업
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const openPopup1 = () => setIsPopupOpen1(true);
  const closePopup1 = () => setIsPopupOpen1(false);




  const [imagePreview, setImagePreview] = useState<string | null>(null);

  // Mypage Form Validation
  const { register: joinRegister, reset, handleSubmit: joinHandleSubmit, getValues: joinGetValues, formState: { errors: joinErrors }, clearErrors: joinClearErrors } = useForm({ mode: 'onChange' });

  useEffect(() => {
    //로그인 여부 CHECK
    dispatch(fetchUser() as any);
    console.log(userState)
    if (!userState.isLogin) {
      alert('로그인 후 사용 가능한 페이지 입니다. \n 로그인 페이지로 이동합니다.')
      navigateAndScroll('/login')
    }

    //나의 문의글 조회
    askLoad();

    //커뮤니티조회
    communityLoad()

  }, []);

  const navigateAndScroll = (path: string) => {
    navigate(path)
    wrapper?.scrollTo({ top: 0, behavior: 'smooth' });
  }


  //Page리스트 변경
  const askPageChange = (page: number) => {
    const viewportHeight = window.innerHeight;
    wrapper?.scrollTo({ top: viewportHeight, behavior: 'smooth' });
    setAskpage(page);
    askLoad(page);
  }

  //목록조회 API
  const askLoad = (pageNumber?: number) => {
    const updatedParams = {
      ...askParams,
      page: pageNumber !== undefined ? pageNumber : askParams.page,
      size: 5,
      my: true,
    };
    console.log('askLoad sendParams: ', updatedParams);

    AskAPI.list(updatedParams)
      .then((res) => {
        console.log('askLoad response: ', res);
        setAskList(res.data);
      })
      .catch((error) => {
        console.log('AskAPI-E: ', error.response?.data?.message);
        setAskList(null);
      });
  };

  //문의글 Click
  const askClick = (item: AskItem, event?: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    navigate('/ask/' + item.id)
    wrapper?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  //Community 목록조회 API
  const communityLoad = (pageNumber?: number) => {
    const updatedParams = {
      ...communityParams,
      page: pageNumber !== undefined ? pageNumber : communityParams.page,
      size: 6,
    };

    console.log('sendParams: ', updatedParams);

    BoardAPI.boardList(updatedParams)
      .then((res) => {
        console.log('response: ', res);
        setCommunityList(res.data);
      })
      .catch((error) => {
        console.log('boardAPI-E: ', error.response?.data?.message);
        setCommunityList(null);
      });
  };

  //커뮤니티글 Click
  const communityClick = (item: BoardItem, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    navigate('/COMMUNITY/' + item.id)
    wrapper?.scrollTo({ top: 0, behavior: 'smooth' });
  }


  //저장
  const onSubmit = async (data: any) => {
    data = {
      ...data, uploadFile: imagePreview, filePath: userState.user.filePath
    }
    console.log(data)
    const response = await dispatch(update(data) as any)
    console.log(response)
    if (response.type === "user/update/fulfilled") {
      alert('정보가 수정되었습니다.')
      joinClearErrors();
      setIsPopupOpen(false);
      dispatch(fetchUser() as any);
      // window.location.reload();
    } else {

    }
  };


  //회원탈퇴
  const quitFun = async () => {
    const data = {} as JoinForm;
    const response = await dispatch(quit(data) as any)
    //TODO change USERAPI
    console.log(response)
    if (response.type === "user/quit/fulfilled") {
      alert('회원 탈퇴되었습니다.')
      navigate('/logout')
      // window.location.reload();
    } else {

    }
    setIsPopupOpen1(false)

  }


  //이미지 수정 
  const camPopup = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsCameraOpen(!isCameraOpen)
  }

  const camEdit = (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setIsCameraOpen(!isCameraOpen)
    document.getElementById('uploadFile')?.click();
  }

  const camDel = (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (window.confirm('파일을 삭제할까요?')) {
      setImagePreview(person)
      setIsCameraOpen(!isCameraOpen)
    }
  }

  // 파일 선택 시 처리
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          setImagePreview(e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };



  return (
    <div>
      <Popup isOpen={isPopupOpen1} onClose={closePopup1} title="정말 탈퇴하시겠어요?">
        <>
          <div style={{ margin: '1rem 0.5rem', fontWeight: '500' }}>
            <p>
              탈퇴 버튼 선택 시, 계정은 복구되지 않습니다.<br />
              탈퇴 후에는 해당 이메일로 <br />
              다시 가입할 수 없습니다.
            </p>
            <button onClick={quitFun} type="button" className="btn mt-2 py-3 btn-danger btn-lg btn-block submit-btn">탈퇴하기</button>
          </div>
        </>
      </Popup>
      <Popup isOpen={isPopupOpen} onClose={closePopup} title="정보수정">
        <div className="profile-wrap">

          <form onSubmit={joinHandleSubmit(onSubmit)} className="form" id="form1">

            <>
              <div className="profile-img-wrap d-flex justify-content-center">
                <img
                  alt="Profile Image" className="profile-img rounded-circle"
                  src={imagePreview ?
                    imagePreview
                    :
                    userState.user.filePath != null ?
                      userState.user.filePath.startsWith('http') ?
                        userState.user.filePath
                        :
                        userState.user.filePath != '' ?
                          `/api/file/getFile?savedPath=${userState.user.filePath}`
                          :
                          person
                      :
                      person
                  }
                />
                <div onClick={camPopup} className={`img-sel-bak ${isCameraOpen ? 'open' : ''}`}>
                  <i className="mt-2 fas fa-camera" />
                  <ul className="profile-control-ul">
                    <li onClick={camEdit} className="profile-control-li">
                      <i className="fa fa-pencil" />
                      <span>수정</span>
                    </li>
                    <li onClick={camDel} className="profile-control-li">
                      <i className="fa fa-trash trash-red"></i>
                      <span className="trash-red">삭제</span>
                    </li>
                  </ul>
                </div>
                <input
                  type="file"
                  id="uploadFile"
                  // {...joinRegister("uploadFile")}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </div>
            </>

            <>
              <span className='span-field'>Email</span>
              <input
                className={`input ${joinErrors.email ? 'red' : ''}`}
                type="text"
                placeholder='Email'
                value={userState?.user.email}
                disabled
              />
              {joinErrors.email?.type === 'required' && <span className='red'>이메일을 입력해주세요.</span>}
              {joinErrors.email?.type === "pattern" && <span className='red'>이메일 형식을 확인해주세요.</span>}
            </>

            <>
              <span className='span-field'>Nickname</span>
              <input
                {...joinRegister("nickname", {
                  required: true
                })}
                className={`input ${joinErrors.nickname ? 'red' : ''}`}
                type="text"
                placeholder='Nickname'
                defaultValue={userState?.user.nickname}
              />
              {joinErrors.nickname?.type === 'required' && <span className='red'>닉네임을 입력해주세요.</span>}
            </>

            <button type="submit" className="btn mt-2 py-3 btn-primary btn-lg btn-block submit-btn">저장</button>

          </form>

        </div>
      </Popup>

      <div className="cmm-container">

        <FadeSlider2 components={slides} text={SLIDE_TEXT} />

        <div className="container mt-5">
          <div className="d-flex justify-content-center align-items-center my-5">
            <span className="col-4 border-bottom mx-2"></span>
            <h1 className="col-4 text-center my-0">MyPage</h1>
            <span className="col-4 border-bottom mx-2"></span>
          </div>
        </div>


        <div className="container-md mt-5" style={{ minHeight: '100vh' }}>
          <div className="row" style={{ minHeight: '70vh' }}>
            <aside className="aside-bg col-md-3 col-xl-3">
              <div className="ABCProfileSection d-flex flex-column align-items-center mb-4">
                <img
                  src={
                    userState.user.filePath != null ?
                      userState.user.filePath.startsWith('http') ?
                        userState.user.filePath
                        :
                        userState.user.filePath != '' ?
                          `/api/file/getFile?savedPath=${userState.user.filePath}`
                          :
                          person
                      :
                      person
                  }
                  className="profile-img mb-2" alt="Profile Image"
                />
                <div className="profile-wrap">
                  <h2 className="profile-name">{userState.user.nickname}님</h2>
                  <p className="mb-1">{userState.user.email}</p>
                  <p className="btn btn-light mt-3 mb-1" style={{ border: '1px solid #cdcdcd' }} onClick={openPopup}>정보 수정</p>
                </div>
              </div>

              <div className="aside-nav mt-5">
                <ul className="list-unstyled">
                  <li className="col mb-2"><a className="aside-nav-a" onClick={() => navigateAndScroll('/askSave')}>문의하기</a></li>
                  <li className="col mb-2"><a className="aside-nav-a" onClick={() => navigateAndScroll('/PRODUCT/store')}>상품보기</a></li>
                  <li className="col mb-2"><a className="aside-nav-a" onClick={() => navigateAndScroll('/COMMUNITY')}>게시글보기</a></li>
                  <li className="col mb-2"><a className="aside-nav-a" onClick={() => navigateAndScroll('/')}>오시는 길</a></li>
                  <li className="col mb-2"><a className="aside-nav-a" onClick={() => navigateAndScroll('/')}>개인정보처리방침</a></li>
                  <li className="col mb-2"><a className="aside-nav-a" onClick={openPopup1}>회원탈퇴</a></li>
                </ul>
              </div>
            </aside>

            <main className="ask-container col-md-9 col-xl-9 p-3 p-4">

              <h3 className="mb-3 " style={{ fontWeight: 600 }}>나의 문의글 현황</h3>
              <div className="ask-list table-responsive">
                <table className="my-table table table-striped table-bordered table-hover">
                  <thead>
                    <tr style={{ backgroundColor: '#f1f1f1' }}>
                      <th>상태</th>
                      <th>종류</th>
                      <th>작성자</th>
                      <th>작성일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {askList?.content?.map((item, index) => (
                      <tr onClick={(event) => askClick(item, event)} key={index}>
                        <td>{ASKSTATUS[item.status]}</td>
                        <td>{ASKCATEGORY[item.status]}</td>
                        <td>{item.name}</td>
                        <td>{item.createdAt}</td>
                      </tr>
                    ))} */}
                    {askList?.content && askList.content.length > 0 ? (
                      askList.content.map((item, index) => (
                        <tr onClick={(event) => askClick(item, event)} key={index}>
                          <td>{ASKSTATUS[item.status]}</td>
                          <td>{ASKCATEGORY[item.status]}</td>
                          <td>{item.name}</td>
                          <td>{item.createdAt}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4} style={{ textAlign: 'center' }}>
                          게시글이 없습니다.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  page={askPage}
                  totalPageCount={askList ? askList.totalPages : 0}
                  onPageChange={askPageChange} />
              </div>

              <h3 className="mb-3 " style={{ fontWeight: 600 }}>추천 게시글</h3>
              <div className="horizontal-scroll-container">
                {communityList?.content.map((item, index) => (
                  // <div onClick={(event) => communityClick(item, event)} key={index} className="hor-item"
                  <div onClick={(event) => communityClick(item, event)} key={index} className="hor-item"
                    style={{ backgroundImage: item.boardFileList[0]?.filePath ? `url(/api/file/getFile?savedPath=${item.boardFileList[0]?.filePath})` : errorImg }}>
                    <div className="hor-text">{item.title + index}</div>
                    <div className="hor-gradient" />
                  </div>
                ))}
              </div>

            </main>
          </div>
        </div>




      </div>
    </div>




  )

}

export default MyPage;