import { useEffect } from "react";
import FadeSlider2 from "../../FadeSlider2";
import VisualSlide from "../../VisualSlide";
import { useNavigate } from "react-router-dom";

const Business = () => {

  const slides = [VisualSlide];
  const SLIDE_TEXT = "Business";
  const navigate = useNavigate()


  useEffect(() => {
    const timer = setTimeout(() => {
      alert('사이트 준비중입니다.');
      navigate('/');
    }, 500);
  
    return () => clearTimeout(timer);
  }, []);


  return (
    <div>

      <div className="cmm-container">

        <FadeSlider2 components={slides} text={SLIDE_TEXT} />

        <div className="container mt-5">
          <div className="d-flex justify-content-center align-items-center my-5">
            <span className="col-4 border-bottom mx-2"></span>
            <h1 className="col-4 text-center my-0">Business</h1>
            <span className="col-4 border-bottom mx-2"></span>
          </div>
        </div>


        <div className="container mt-5" style={{ minHeight: '100vh' }}>
          <div className="d-flex justify-content-between mt-2">
            {/* <button type="button" className="btn btn-primary mb-5" onClick={goSaveForm}>등록하기</button> */}
            {/* <button type="button" className="btn btn-primary mb-5" onClick={submitPost}>게시글 등록</button> */}
          </div>
          <div className="" style={{ overflowX: 'auto' }}>
            <table className="table table-striped table-hover align-middle">
              {/* status, category, name, createdAt*/}
              <colgroup>
                <col style={{ minWidth: '70px' }} />{/* 상태 */}
                <col style={{ minWidth: '100px' }} />{/* 종류 */}
                <col style={{ minWidth: '100px' }} />{/* 작성자 */}
                <col style={{ minWidth: '150px' }} />{/* 작성일 */}
                <col style={{ minWidth: '150px' }} />{/* 작성일 */}
              </colgroup>
              <thead style={{ backgroundColor: 'rgb(239 239 239)', color: '#343a40', borderBottom: '1px solid #dee2e6', textAlign: 'center' }}>
                <tr>
                  <th scope="col" style={{ fontFamily: 'Roboto' }}>상태</th>
                  <th scope="col" style={{ fontFamily: 'Roboto' }}>종류</th>
                  <th scope="col" style={{ fontFamily: 'Roboto' }}>작성자</th>
                  <th scope="col" style={{ fontFamily: 'Roboto' }}>작성일</th>
                  <th scope="col" style={{ fontFamily: 'Roboto' }}>작성일</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: 'center' }}>
                <>
                  <tr>
                    <th scope="row">회사</th>
                    <td>몇개</td>
                    <td>들어</td>
                    <td>가</td>
                    <td>겠지</td>
                  </tr>
                  <tr>
                    <th scope="row">회사</th>
                    <td>몇개</td>
                    <td>들어</td>
                    <td>가</td>
                    <td>겠지</td>
                  </tr>
                  <tr>
                    <th scope="row">회사</th>
                    <td>몇개</td>
                    <td>들어</td>
                    <td>가</td>
                    <td>겠지</td>
                  </tr>
                </>
              </tbody>
            </table>
            {/* <Pagination
            page={page}
            totalPageCount={askList ? askList.totalPages : 0}
            onPageChange={pageChange} /> */}
          </div>
        </div>


      </div>
    </div>




  )

}

export default Business;