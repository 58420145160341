//import '../styles/base.css'
import { useNavigate } from 'react-router-dom'
import BoardAPI from '../../apis/BoardAPI'

import { useDispatch, useSelector } from 'react-redux'
import { setExceptionStatus } from '../../redux/reducers/exceptionSlice'
import { useEffect } from 'react'
import { RootState } from '../../redux/reducers/RootReducer'

const BoardPage = () => {
  
    const exceptionState = useSelector((state: RootState) => state.exception.code);
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
  
    useEffect(() => {
      // BoardAPI.boardList()
      //   .then((response) => {
      //     console.log('게시글 목록조회: ', response)
      //   }).catch((error) => {
      //     dispatch(setExceptionStatus(error.response.status))
      //   })
    }, []);
  
  
    useEffect(() => {
      if (exceptionState === 401) {
        navigate('/login')
        alert('로그인이 필요한 서비스 입니다')
        dispatch(setExceptionStatus(null)); // 예외 상태 초기화
      }
    }, [exceptionState]);
  
  
    return (
      <div>
        <h1>Board</h1>
        <p>Board Page</p>
      </div>
    )

}

export default BoardPage;
