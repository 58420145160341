import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/actions/userAsyncsActions";
import Dummy from "../Dummy";

const KakaoLogOut = () => {
  
      const dispatch = useDispatch();
  
  
      useEffect(() => {
          // Use window.location instead of directly using location
          window.location.href = 'https://kauth.kakao.com/oauth/logout?client_id=de060398e6a041e609f9176ae55b108d&logout_redirect_uri=http://localhost:3000/';
          // dispatch(logout() as any);
          // navigate('/')
      }, []);
  
      
      return (
        <div>
          <h1>kakaoLogOut</h1>
          <p>kakaoLogOut Page</p>
          <Dummy/>
        </div>
      )
  
}

export default KakaoLogOut;