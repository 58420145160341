export const DEV_MODE = false;

// export const ASKSTATUS = {
//   1: '답변대기',
//   2: '답변중',
//   3: '답변완료'
// } as const;
export const ASKSTATUS = ['','답변대기','답변중','답변완료']
export const ASKCATEGORY = ['전체','상품문의','레슨문의','기타문의']

/** 권한 */
export enum Role {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
  GUEST = 'ROLE_GUEST'
}


/** 메뉴 */
export interface MenuItem {
  NAME: string;
  LINK: string;
}

export interface MenuDeps {
  NAME: string;
  LINK: string;
  MENU_ITEM: MenuItem[];
}

export interface Menu {
  [key: string]: MenuDeps;
}

export const YOONSKINMENU: Menu = {
  INTRODUCE: {
    NAME: '소개',
    LINK: 'INTRODUCE',
    MENU_ITEM: [
    ]
  },
  BUSINESS: {
    NAME: '비즈니스',
    LINK: 'BUSINESS',
    MENU_ITEM: [
      { NAME: '비스니스', LINK: 'BUSINESS' }
    ]
  },
  PRODUCT: {
    NAME: '상품',
    LINK: 'PRODUCT/store',
    MENU_ITEM: [
      { NAME: '스토어', LINK: 'PRODUCT/store' },
      // { NAME: '팩', LINK: 'vor' },
      // { NAME: '화장품', LINK: 'ghkwkdvna' },
      // { NAME: '또 뭐있냐', LINK: 'Eh anjdlTsi' },
      // { NAME: '기타', LINK: 'rlxk' }
    ]
  },
  COMMUNITY: {
    NAME: '커뮤니티',
    LINK: 'COMMUNITY',
    MENU_ITEM: [
      { NAME: '커뮤니티', LINK: 'COMMUNITY' },
      { NAME: '릴스', LINK: 'reels' },
      { NAME: '문의', LINK: 'ask' },
    ]
  },
};
export type TopNavTab = keyof typeof YOONSKINMENU;

/** 회원정보 */
export interface User {
  username: string;
  nickname: string;
  status: string;
  roles: string;
  gender: string;
  telno: string;
  birth: string;
  email: string;
  address: string;
  detailAddress: string;
  filePath: string;
}

/** 회원가입폼 */
export interface JoinForm {
  email: string;
  password: string;
  passwordConfirm: string;
  username: string;
  nickname: string;
  gender: string;
  telno: string;
  birth: string;
  address: string;
  detailAddress: string;
  uploadFile: string;
  //bindingResult: Validation;
}

/** 로그인폼 */
export interface Authentication {
  username_field: string;
  password_field: string;
}
/** UserSlice */
export interface UserState {
  user: User;
  dataLoad: boolean;
  isLogin: boolean;
  error: string;
}

/** 유효성 검사 */
export interface Validation {
  success: boolean,
  validationResult: ValidationResult | undefined,
}

export interface ValidationResult {
  errors: Error[]
}


export interface Error {
  field: string,
  code: string,
  rejectedValue: string,
  message: string,
}


// /** 페이징 */
// export interface Page {
//   page: number,
//   size: number,
// }

// /** 게시글 */
// export interface Board {
//   id: number,
//   page: Page,
// }

/** 상품 */
export interface Product {
  id: number;
}

/** Header.tsx */
export interface ChildMenuProps {
  title: string;
  menuDeps: MenuDeps
}

/** Common List  */

export interface PageableSort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface Pageable {
  sort: PageableSort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface Page<T> {
  content: T[];
  pageable: Pageable;
  totalElements: number;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  sort: Sort;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}
/** Common List  */


/** Community List */

/** CommunitySaveForm */
export interface EditorComponentProps {
  injectContent?: string | undefined;
  onContentChange: (param: string) => void;
  onImgChange: (param: string[]) => void;
  imgs?: string[] | undefined;
}

export interface BoardSaveForm {
  id?: number | undefined;
  uploadFiles: string[] | undefined;
  title: string | undefined;
  content: string | undefined;
}

/** CommunitySaveForm */

export interface BoardItem {
  id: number;
  title: string;
  link: string;
  content: string;
  enabled: number;
  createdId: number;
  createdAt: string;
  boardFileList: BoardFile[];
}

export interface BoardFile {
  id: number;
  fileRealName: string;
  fileStoreName: string;
  filePath: string;
  createdAt: string;
  boardId: number;
}

export interface CommunityParams {
  id?: string | undefined;
  searchKeyword?: string | undefined;
  searchType?: string | undefined;
  page: number;
  size?: number;
  sort?: string | undefined;
  my?: boolean;
}

export const initCommunityParams: CommunityParams = {
  searchKeyword: undefined,
  searchType: undefined,
  page: 1,
  size: 10,
  sort: undefined,
};
/** Community List */

/** Reels List */

export interface InstagramResponse {
  data: ReelsItem[];
  paging: Paging;
}

export interface ReelsItem {
  id: string | undefined;
  media_type: string | undefined;
  media_url: string | undefined;
  permalink: string | undefined;
  thumbnail_url: string | undefined;
  username: string | undefined;
  caption: string | undefined;
}

export interface Cursors {
  before: string | undefined;
  after: string | undefined;
}

export interface Paging {
  cursors: Cursors;
  next: string;
  previous: string;
}

export interface ReelsParams {
  next?: string | undefined;
  previous?: string | undefined;
  limit?: number | undefined;
}

/** Reels List */





/** Ask */
export interface AskParams {
  id?: string | undefined;
  searchKeyword?: string | undefined;
  searchType?: string | undefined;
  page: number;
  size?: number;
  sort?: string | undefined;
  my?: boolean;
}

export const initAskParams: AskParams = {
  searchKeyword: undefined,
  searchType: undefined,
  page: 1,
  size: 10,
  sort: undefined,
};

export interface AskSaveForm {
  id?: number | undefined;
  lessonPurpose: string | undefined;
  name: string | undefined;
  email: string | undefined;
  telno: string | undefined;
  content: string | undefined;
  comment: string | undefined;
  uploadFiles: string[] | undefined;
}



export interface AskItem {
  id?: number;
  title: string;
  email: string;
  name: string;
  telno: string;
  category: number;
  lessonPurpose: string;
  content: string;
  comment: string;
  status: number;
  enabled: number;
  createdId: number;
  createdAt: string;
  link: string;
  uploadFiles: string[];
  //boardFileList: BoardFile[];
}

/** Ask */

/** Store */
export interface StoreParams {
  id?: string | undefined;
  searchKeyword?: string | undefined;
  searchType?: string | undefined;
  page: number;
  size?: number;
  sort?: string | undefined;
}

export const initStoreParams: StoreParams = {
  searchKeyword: undefined,
  searchType: undefined,
  page: 1,
  size: 10,
  sort: undefined,
};

export interface StoreSaveForm {
  id?: number | undefined;
  lessonPurpose: string | undefined;
  name: string | undefined;
  email: string | undefined;
  telno: string | undefined;
  content: string | undefined;
  comment: string | undefined;
  uploadFiles: string[] | undefined;
}


export const GET_PRODUCT_URL = 'https://smartstore.naver.com/yoonskinwellness/products/'
export interface StoreItem {
  id?: number;
  title: string;
  email: string;
  name: string;
  telno: string;
  category: number;
  lessonPurpose: string;
  content: string;
  comment: string;
  status: number;
  enabled: number;
  createdId: number;
  createdAt: string;
  link: string;
  uploadFiles: string[];
  //boardFileList: BoardFile[];
}


export interface CommerceResponse {
  contents: ProductContent[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  sort: Sort;
  first: boolean;
  last: boolean;
}

export interface ProductContent {
  originProductNo: number;
  channelProducts: ChannelProduct[];
}

export interface ChannelProduct {
  originProductNo: number;
  channelProductNo: number;
  channelServiceType: string;
  categoryId: string;
  name: string;
  statusType: string;
  channelProductDisplayStatusType: string;
  salePrice: number;
  discountedPrice: number;
  mobileDiscountedPrice: number;
  stockQuantity: number;
  knowledgeShoppingProductRegistration: boolean;
  deliveryAttributeType: string;
  deliveryFee: number;
  returnFee: number;
  exchangeFee: number;
  managerPurchasePoint: number;
  wholeCategoryName: string;
  wholeCategoryId: string;
  representativeImage: RepresentativeImage;
  sellerTags: SellerTag[];
  regDate: string;
  modifiedDate: string;
}

export interface RepresentativeImage {
  url: string;
}

export interface SellerTag {
  code: string;
  text: string;
}

export interface Sort {
  sorted: boolean;
  fields: Field[];
}

export interface Field {
  name: string;
  direction: string;
}

/** Store */