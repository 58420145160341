import React, { ReactNode } from 'react';
import '../../styles/popup.css';

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
}

const Popup = ({ isOpen, onClose, title, children } : PopupProps) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h5>{title}</h5>
          <button onClick={onClose} className="popup-close-button">&times;</button>
        </div>
        <div className="popup-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
