import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthorization from '../../../hooks/useAuthorization';
import VisualSlide from "../../VisualSlide";
import FadeSlider2 from '../../FadeSlider2';

import BoardAPI from '../../../apis/BoardAPI';
import { BoardItem, Role, initCommunityParams } from '../../../types/types';
import { RootState } from '../../../redux/reducers/RootReducer';
import { useSelector } from 'react-redux';

/**
 * api response
{"id":30,"title":"첫번쨰 게시글1","link":null,"content":"<p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p>","enabled":1,"createdId":16,"createdAt":"2024-01-25","updatedId":null,"updatedAt":null,"deletedId":null,"deletedAt":null,"boardFileList":[{"id":16,"fileRealName":"file1.png","fileStoreName":"e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png","filePath":"/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png","createdAt":"2024-01-25","boardId":30}]}
 */


const CommunityDetail = () => {
  const SLIDE_TEXT = "Community";
  const slides = [VisualSlide];
  const { id } = useParams();
  const [boardItem, setBoardItem] = useState<BoardItem | null>(null);
  const [params, setParams] = useState(initCommunityParams);
  const handleAuth = useAuthorization();
  const userState = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  //랜더링 시 API 호출
  useEffect(() => {
    const param = {
      ...params,
      id: (id !== undefined) ? id : undefined,
    };


    BoardAPI.detail(param)
      .then((res) => {
        setBoardItem(res.data);
      })
      .catch((error) => {
        console.log('boardAPI-E: ', error.response?.data?.message);
        setBoardItem(null);
        alert('잠시 후에 다시 시도해주세요.')
        // navigate('/COMMUNITY')
      });

  }, []);


  const moveEditForm = () => {
    if (handleAuth)
      navigate('/COMMUNITY/' + id + '/edit', { replace: false })
  }

  const movePriv = () => {
    navigate(-1)
  }

  const deleteBoard = () => {
    if(window.confirm('삭제하시겠습니까?')){
      if (handleAuth){
        const param = {
          ...params,
          id: (id !== undefined) ? id : undefined,
        };
        BoardAPI.deleteBoard(param)
          .then((res) => {
              alert('삭제되었습니다.')
              navigate('/COMMUNITY', { replace: false })
          })
          .catch((error) => {
            alert('삭제를 실패하였습니다. 잠시 후 다시 시도해주세요.')
          });
      } else {
        alert('비정상 적인 접근')
        navigate('/', { replace: false })
      }
    }
  }

  return (
    <div>
      <FadeSlider2 components={slides} text={SLIDE_TEXT} />
      {/* TODO: 작성자, 작성일, 조회수 보여주는 칸 추가 참고: https://91cosmedi.com/press-release/?mod=document&pageid=1&uid=24 */}
      {/* TODO: 목록으로 돌아가기 */}
      <section className="py-5" style={{minHeight: '80vh'}}>
        <div className="container px-4 px-lg-5 my-5">
          <button type="button" className="btn btn-secondary mb-5" onClick={movePriv}>뒤로가기</button>
          {(handleAuth && userState.user.roles === Role.ADMIN) ? 
            <button type="button" className="btn btn-primary mb-5" style={{marginLeft: '10px'}} onClick={moveEditForm}>수정하기</button> : ''
          }
          {(handleAuth && userState.user.roles === Role.ADMIN) ? 
            <button type="button" className="btn btn-primary mb-5" style={{marginLeft: '10px'}} onClick={deleteBoard} >삭제하기</button> : ''
          }
          <div className="col-md-12">
            {/* <div className="small mb-1">SKU: BST-498</div> */}
            <h1 className="display-5 fw-bolder mb-5">{boardItem?.title}</h1>
          </div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{ __html: (boardItem?.content) ? boardItem?.content : '' }}
          // dangerouslySetInnerHTML={{ __html: '<p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p>' }}
          />
        </div>
      </section>

    </div>
  );
};

export default CommunityDetail;




{/* 
        <div className="row gx-4 gx-lg-5 align-items-center">
            <div className="col-md-6"><img className="card-img-top mb-5 mb-md-0" src="https://dummyimage.com/600x700/dee2e6/6c757d.jpg" alt="..." /></div>
            <div className="col-md-6">
                <div className="small mb-1">SKU: BST-498</div>
                <h1 className="display-5 fw-bolder">Shop item template</h1>
                <div className="fs-5 mb-5">
                    <span className="text-decoration-line-through">$45.00</span>
                    <span>$40.00</span>
                </div>
                <p className="lead">Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium at dolorem quidem modi. Nam sequi consequatur obcaecati excepturi alias magni, accusamus eius blanditiis delectus ipsam minima ea iste laborum vero?</p>
                <div className="d-flex">
                    <input className="form-control text-center me-3" id="inputQuantity" type="num" value="1" />
                    <button className="btn btn-outline-dark flex-shrink-0" type="button">
                        <i className="bi-cart-fill me-1"></i>
                        Add to cart
                    </button>
                </div>
            </div>
        </div>
        */}