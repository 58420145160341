import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import RootReducer from "./reducers/RootReducer";


const Store = configureStore({
    reducer: RootReducer,
    devTools: true,
    middleware: [
      thunkMiddleware, 
    ],
});

export default Store;