// joinSlice.ts

import { createSlice } from "@reduxjs/toolkit";
import { userAsyncActions } from "../actions/userAsyncsActions";
import { Validation } from "../../types/types";

const initialState: Validation = {
  success: false,
  validationResult: {
    errors: []
  }
}

interface ServerError {
  success: boolean;
  message: string;
}

const joinSlice = createSlice({
  name: 'join',
  initialState: initialState,
  reducers: {
    setJoin: (state, action) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(userAsyncActions.join2.pending, (state, action) => {
      state.success = false;
      state.validationResult = undefined;
    })
    builder.addCase(userAsyncActions.join2.fulfilled, (state, action) => {
      state.success = true;
      state.validationResult = undefined;
    })
    builder.addCase(userAsyncActions.join2.rejected, (state, action) => {
      const validation = action.payload as Validation;
      state.success = false;
      state.validationResult = validation.validationResult;
      // console.log(action.payload)
      const error = action.payload as ServerError;
      if (error?.message) {
        alert(error.message); // 에러 메시지를 alert로 띄웁니다.
      }
    });
  }
});

export const { setJoin } = joinSlice.actions;

export default joinSlice.reducer;