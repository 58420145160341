import React, { useEffect, useState } from 'react';
import ppt2 from '../assets2/format/ppt2.png';

const PPT2: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".ppt2-wrap");
      if (!section) return;

      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const threshold = windowHeight / 1.3;

      if (rect.top <= threshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    document.getElementById('wrapper')?.addEventListener("scroll", handleScroll);
    return () => {
      document.getElementById('wrapper')?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (

    <div className='ppt2-wrap' style={{ display: 'flex', flexWrap: 'wrap', marginTop: '7rem' }}>
      <div className="ppt2-item ppt2-fir" style={{}}>
        <div className='ppt2-con'>
          <p className={`ppt2-txt1 ${isVisible ? "fadeIn" : "fadeOut"} `}>
            광주최초, 운동과 피부관리까지 <br/>
            한번에 가능한 트레이너 <br/>
            <span className='ppt2-txt1 orange'>"웰니스"</span>에 특화된 윤스킨웰니스
          </p>
          <p className={`ppt2-txt2 ${isVisible ? "fadeIn" : "fadeOut"} `}>
            건강의 지표는 <br className="mo-only" />단순히 체지방이 적고 예쁜 몸매만이 아닌,<br/>
            정신적 사회적 육체적으로 건강한 상태를 말합니다.<br/>
            꿈구는 삶이 있나요? 당신은 꿈보다 <span className='ppt2-txt2 orange'>야망</span>이 필요합니다.<br/>
            윤스킨웰니스에서 당신의 삶의 변화에<br/>
            <span className='ppt2-txt2 orange'>확실한</span> 보탬이 되어 드리겠습니다.<br/>
          </p>
        </div>
      </div>
      <div className="ppt2-item">
        <img src={ppt2} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
    </div>

  );
};

export default PPT2;