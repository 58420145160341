import React, { useEffect, useState } from 'react';
import ppt3back from '../assets2/format/3.png';
import ppt1 from '../assets2/download/pedro-mestres-yQKp5Xt2h0M-unsplash.jpg';


const PPT3: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const wrapper = document.getElementById('wrapper');
      const parallaxBackground = document.querySelector(".parallax-background") as HTMLElement;
      if (wrapper) {
        const rect = parallaxBackground.getBoundingClientRect();
        const scrollValue = Math.floor(rect.top);
        const scrollValue1 = -scrollValue;
        if (parallaxBackground) {
          parallaxBackground.style.transform = `translate3d(0, ${scrollValue1 * 0.7}px, 0)`;
        }
      }
    };
  
    document.getElementById('wrapper')?.addEventListener("scroll", handleScroll);
    return () => {
      document.getElementById('wrapper')?.removeEventListener("scroll", handleScroll);
      document.querySelector(".parallax-background")?.removeEventListener("scroll", handleScroll);
    };
    
  }, []);
  


  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".ppt3-wrap");
      if (!section) return;

      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const threshold = windowHeight / 1.3;

      if (rect.top <= threshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    document.getElementById('wrapper')?.addEventListener("scroll", handleScroll);
    return () => {
      document.getElementById('wrapper')?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (

    // <div className='ppt3-wrap'>
    //   <div className='ppt3-back-img1'>
    //     hi
    //   </div>
    //   <div className='ppt3-back-img2'>
    //     hi
    //   </div>
    // </div>

    <div>
      <div className="parallax-container">
      <div className='parallax-window'></div>
        <img className="parallax-background" src={ppt1} alt="Parallax Background" />
        <div className="parallax-content">
          <p className="parallax-txt parallax-txt1">
            건강과 아름다움을 하나로<br/>
          </p>
          <p className="parallax-txt">
            당신의 아름다움은 여기서 시작됩니다.<br/>
            아름다운 자신을 발견하고 변화하세요.<br/>
          </p>
        </div>
      </div>
    </div>

  );
};

export default PPT3;