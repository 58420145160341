import axios, { AxiosResponse } from "axios";
import api from './api'
import { JoinForm, User } from "../types/types";

const initUser: User = {
  username: '',
  nickname: '',
  status: '',
  roles: '',
  gender: '',
  telno: '',
  birth: '',
  email: '',
  address: '',
  detailAddress: '',
  filePath: '',
}

/** 회원조회 */
const me = (): Promise<AxiosResponse<User | undefined>> => {
  return api.get('/user/me').then((response) => {
    if (typeof response.data === 'string' && response.data === "") {
      return { ...response, data: undefined };
    } else {
      return response;
    }
  })
  .catch((error) => {
    throw error;
  });
}

/** 회원조회 */
const me2 = (): Promise<User> => {
  return api.get('/user/me').then((response) => {
    if (typeof response.data === 'string' && response.data === "") {
      return initUser;
    } else {
      return response.data;
    }
  })
  .catch((error) => {
    throw error;
  });
}

/** 회원가입 */
const join = (param: JoinForm) => {
  const formData = new FormData();
  Object.entries(param).forEach(([key, value]) => {
    formData.append(key, value);
  });

    return api.post('/user/join', formData);
}

/** 회원가입 */
// const update = (param: JoinForm) => {
//   const formData = new FormData();
//   Object.entries(param).forEach(([key, value]) => {
//     formData.append(key, value);
//   });

//     return api.post('/user/update', formData);
// }


const update = async (param: JoinForm) => {
  const formData = new FormData();

  await Promise.all(
    Object.entries(param).map(async ([key, value]) => {
      if (key === 'uploadFile' && value != null) {//  && Array.isArray(value) && value.length > 0

        const updatedUploadFiles = await switchBase64(value);
        
        const filePromises = updatedUploadFiles.map(async (url, index) => {
          const response = await fetch(url);
          const blob = await response.blob();
          
          return new File([blob], `file${index + 1}.png`, { type: 'image/png' });
        });
        
        const files = await Promise.all(filePromises);
        
        files.forEach((file, index) => {
          console.log(`${key}`, file);
          formData.append(`${key}`, file);
        });
        
      } else {
        formData.append(key, value);
      }
    })
  );
  console.log('formData: ', formData)
  return api.post('/user/update', formData
  , {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const quit = async (param: JoinForm) => {
  const formData = new FormData();

  await Promise.all(
    Object.entries(param).map(async ([key, value]) => {
      if (key === 'uploadFile' && value != null) {//  && Array.isArray(value) && value.length > 0

        const updatedUploadFiles = await switchBase64(value);
        
        const filePromises = updatedUploadFiles.map(async (url, index) => {
          const response = await fetch(url);
          const blob = await response.blob();
          
          return new File([blob], `file${index + 1}.png`, { type: 'image/png' });
        });
        
        const files = await Promise.all(filePromises);
        
        files.forEach((file, index) => {
          console.log(`${key}`, file);
          formData.append(`${key}`, file);
        });
        
      } else {
        formData.append(key, value);
      }
    })
  );
  console.log('formData: ', formData)
  return api.post('/user/quit', formData
  , {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};



//base64 인코딩이 아니면 enode
const switchBase64 = async (uploadFiles: any) => {
  const updatedUploadFiles = [];
  
  if (uploadFiles.startsWith('/api/file/')) {// 주어진 값이 '/api/file/'로 시작하는 경우
    const response = await fetch(uploadFiles);// 해당 경로에 있는 파일을 가져와서 Base64로 인코딩
    const blob = await response.blob();
    const reader = new FileReader();
    
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64Data = reader.result;
      updatedUploadFiles.push(base64Data);
    };
  } else {
    updatedUploadFiles.push(uploadFiles);
    // if(uploadFiles.startsWith('data:image')) {
    // }else {
    // }
  }

  return updatedUploadFiles;
};


const UserAPI = { join, me, me2, update, quit };
export default UserAPI;