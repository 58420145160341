import Dummy from "../../Dummy";
import VisualSlide from "../../VisualSlide";
import FadeSlider2 from "../../FadeSlider2";
import visual1 from '../../../assets2/test04.png';

import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import { AskSaveForm } from "../../../types/types";
import AskAPI from "../../../apis/AskAPI";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/RootReducer";
const Ask = () => {

  const SLIDE_TEXT = "Ask";
  const slides = [VisualSlide];
  const navigate = useNavigate();
  const userState = useSelector((state: RootState) => state.user);
  const [submitted, setSubmitted] = useState(false)
  const { register, handleSubmit, getValues, formState: { errors } } = useForm({ mode: 'onChange' });

  const onSubmit = (data: any) => {
    // category: "1"
    // email: "yoonSkin@yoonSkin.com"
    // message: "궁금하신 내용들을 적어주세요!"
    // name: "윤스킨"
    // telno: "01012345678"
    if (userState.isLogin) {
      const ask = data as AskSaveForm;
      console.log(ask)
      AskAPI.save(ask).then((res) => {
        alert('등록되었습니다.')
        navigate('/askList', { replace: true })
      }).catch((e) => {
        alert("error 발생: " + e)
      });
    } else {
      // TODO: 로그인 성공 후 다시 돌아와야함
      if (window.confirm('로그인 후 작성가능합니다. 로그인 페이지로 이동할까요?')) {
        navigate('/login', { replace: true })
      };
    }

  };


  return (
    <div>

      <FadeSlider2 components={slides} text={SLIDE_TEXT} />

      <div className="d-flex justify-content-between mt-2">
        {/* <button type="button" className="btn btn-secondary mb-5" onClick={prevBtn}>뒤로가기</button>
        <button type="button" className="btn btn-primary mb-5" onClick={submitPost}>게시글 등록</button> */}
        
      </div>

      <div className="container mt-8">
        <div className="d-flex justify-content-center align-items-center my-5">
          <span className="col-4 border-bottom mx-2"></span>
          <h1 className="col-4 text-center my-0">Contact Us</h1>
          <span className="col-4 border-bottom mx-2"></span>
        </div>
      </div>

      <div className="container my-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <img src={visual1} alt="Contact Image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <div className="col-md-6 form-container" style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="form-group mb-3">
                <label>Category</label>
                {/* aria-label="Default select example" */}
                <select className={`form-select ${submitted ? errors.category ? 'is-invalid' : 'is-valid' : ''}`}  {...register("category", { required: true })}>
                  <option value="" defaultValue={''}>전체</option>
                  <option value="1">상품문의</option>
                  <option value="2">레슨문의</option>
                  <option value="3">기타문의</option>
                </select>
                <div className="valid-feedback">Ok!</div>
                {errors.category && <div className="invalid-feedback">카테고리를 선택하세요.</div>}
              </div>
              <div className="form-group mb-2">
                <label>Name</label>
                <input type="text" className={`form-control ${submitted ? errors.name ? 'is-invalid' : 'is-valid' : ''}`}
                  {...register("name", { required: true })} placeholder="윤스킨" />
                <div className="valid-feedback">Ok!</div>
                <div className="invalid-feedback">이름을 입력하세요.</div>
              </div>
              <div className="form-group mb-2">
                <label>Email</label>
                <input type="email" className={`form-control ${submitted ? errors.email ? 'is-invalid' : 'is-valid' : ''}`}
                  {...register("email", { required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
                  placeholder="yoonSKin@yoonSkin.com" />
                <div className="valid-feedback">Ok!</div>
                <div className="invalid-feedback">유효한 이메일을 입력하세요.</div>
              </div>
              <div className="form-group mb-2">
                <label>Phone</label>
                <input type="tel" className={`form-control ${submitted ? errors.telno ? 'is-invalid' : 'is-valid' : ''}`}
                  {...register("telno", { required: true, pattern: /^\d{11}$/ })} placeholder="01012345678" />
                <div className="valid-feedback">Ok!</div>
                <div className="invalid-feedback">유효한 전화번호를 입력하세요.</div>
              </div>
              <div className="form-group mb-2">
                <label>Message</label>
                <textarea className={`form-control ${submitted ? errors.message ? 'is-invalid' : 'is-valid' : ''}`}
                  {...register("content", { required: true })} placeholder="궁금하신 내용을 적어주세요!"></textarea>
                <div className="valid-feedback">Ok!</div>
                <div className="invalid-feedback">메시지를 입력하세요.</div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4 gap-3">
            <button type="button" onClick={() => { navigate('/askList') }} className="btn btn-secondary">목록으로</button>
            <button type="submit" onClick={() => { setSubmitted(true) }}   className="btn btn-primary">제출하기</button>
          </div>
        </form>
      </div>


    </div>


  );


}

export default Ask;





{/* askSave useForm 유효성검사
  <div className="form-group">
                <label>Category</label>
                <select className="form-select" aria-label="Default select example" {...register("category", { required: true })}>
                  <option value="">전체</option>
                  <option value="1">상품문의</option>
                  <option value="2">레슨문의</option>
                  <option value="3">기타문의</option>
                </select>
                {errors.category && <span className="text-danger">Category is required</span>}
              </div>
              <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" {...register("name", { required: true })} />
                {errors.name && <span className="text-danger">Name is required</span>}
              </div>
              <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                {errors.email && <span className="text-danger">Valid email is required</span>}
              </div>
              <div className="form-group">
                <label>Phone</label>
                <input type="tel" className="form-control" {...register("phone")} />
              </div>
              <div className="form-group" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <label>Message</label>
                <textarea className="form-control" style={{ height: '100%' }} {...register("message", { required: true })}></textarea>
                {errors.message && <span className="text-danger">Message is required</span>}
              </div> */}