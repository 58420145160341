import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import next from "../assets/icons8-next-64.png"
import prev from "../assets/icons8-prev-64.png"

const SampleNextArrow: React.FC<any> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + ' next-wrap'}
      style={{ ...style, 
        backgroundImage: next,
        position: 'absolute',
        right: '15%',
        zIndex: '1',
        width: 'auto',
        height: 'auto',
      }}
      onClick={onClick}
    >
      <img className="arrow-next" src={next} alt="다음버튼" style={{opacity: 0.7}}/>
    </div>
  );
};

const SamplePrevArrow: React.FC<any> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + ' prev-wrap'}
      style={{ ...style, 
        backgroundImage: prev,
        position: 'absolute',
        left: '15%',
        zIndex: '1',
        width: 'auto',
        height: 'auto',
      }}
      onClick={onClick}
    >
      <img className="arrow-prev" src={prev} alt="이전버튼" style={{opacity: 0.7}}/>
    </div>
  );
};

const SwipeSection = () => {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".swipe-con");
      if (!section) return;

      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const threshold = windowHeight / 1.2;

      if (rect.top <= threshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    document.getElementById('wrapper')?.addEventListener("scroll", handleScroll);

    return () => {
      document.getElementById('wrapper')?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    dots: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 4000,
    centerPadding: "390px",
    slidesToShow: 1,
    speed: 500,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          centerPadding: "230px",
        },
      },
      {
        breakpoint: 1025,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "80px",
        },
      },
    ],

  };


  return (

    <div className="swipe-sect-wrap">

      <div className="titlebox">
        <p className="subtit">BEFORE & AFTER</p>
        <div className="subt-wrap">
          <div className="subt-ani">
            <p className={isVisible ? "subt-act" : "subt-inact"}>
              <span className="subt-con">" 어제와 다른 오늘 "</span>
            </p>
          </div>
        </div>
      </div>

      <div className="swipe-con">
        <Slider {...settings}>
          <div>
            <div className="swipe-img-con">
              <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
              <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
            </div>
          </div>
          <div>
            <div className="swipe-img-con">
            <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
              <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
            </div>
          </div>
          <div>
            <div className="swipe-img-con">
            <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
              <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
            </div>
          </div>
          <div>
            <div className="swipe-img-con">
            <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
              <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
            </div>
          </div>
          <div>
            <div className="swipe-img-con">
            <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
              <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
            </div>
          </div>
          <div>
            <div className="swipe-img-con">
            <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
              <img className="swipe-img" src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" alt="Total Solution 이미지" />
            </div>
          </div>
        </Slider>
      </div>
    </div>
    
  );
}

{/** 
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img11.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img12.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img12.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img13.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img13.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img14.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img01.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img01.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img02.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img02.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img03.jpg" alt="" class="img">


<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img04.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img05.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img04.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img05.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img06.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img07.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img06.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img07.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img08.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img09.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img08.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img09.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img10.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img11.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img10.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img11.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img12.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img12.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img13.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img13.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img14.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img01.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img01.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img02.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img02.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img03.jpg" class="img-fluid" alt="">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img03.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img04.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-detail-img05.jpg" alt="" class="img">
<img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-beforeafter-img04.jpg" class="img-fluid" alt="">
 */}

export default SwipeSection;