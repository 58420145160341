import axios, { AxiosResponse } from "axios";
import api from './api'
import { AskParams, AskSaveForm } from "../types/types";


const list = (params: AskParams) => {
  params.page = params.page-1;
  return api.get('/api/product/store?sort=askId,DESC', { params });
}

const detail = (params: AskParams) => {
  return api.get('/api/ask/ask', { params });
}

// const save = (param: BoardSaveForm) => {
//   const formData = new FormData();
//   Object.entries(param).forEach(([key, value]) => {
//     formData.append(key, value);
//   });
//   return api.post('/api/board/board/save', formData);
// }

// const save = (param: BoardSaveForm) => {
//   const formData = new FormData();

//   Object.entries(param).forEach(([key, value]) => {
//     if (key === 'uploadFiles' && Array.isArray(value) && value.length > 0 ) {
//       value.forEach((file, index) => {
//         //해당 로직에서 file값은 string 형태의 src 이다 이 값을 가지고 file을 만들어줘
//       });
//     } else {
//       formData.append(key, value);
//     }
//   });

//   return api.post('/api/board/board/save', formData);
// };

const save = async (param: AskSaveForm) => {
  const formData = new FormData();

  await Promise.all(
    Object.entries(param).map(async ([key, value]) => {
      if (key === 'uploadFiles' && Array.isArray(value) && value.length > 0) {
        const filePromises = value.map(async (url, index) => {
          const response = await fetch(url);
          const blob = await response.blob();

          return new File([blob], `file${index + 1}.png`, { type: 'image/png' });
        });

        const files = await Promise.all(filePromises);
        
        files.forEach((file, index) => {
          formData.append(`${key}`, file);
        });
        
      } else {
        formData.append(key, value);
      }
    })
  );

  return api.post('/api/ask/ask/save', formData
  , {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const update = async (param: AskSaveForm) => {
  const formData = new FormData();

  await Promise.all(
    Object.entries(param).map(async ([key, value]) => {
      if (key === 'uploadFiles'  && Array.isArray(value) && value.length > 0) {

        // switchBase64 함수를 사용하여 이미지 파일을 처리
        const updatedUploadFiles = await switchBase64(value);

        const filePromises = updatedUploadFiles.map(async (url, index) => {
          const response = await fetch(url);
          const blob = await response.blob();

          return new File([blob], `file${index + 1}.png`, { type: 'image/png' });
        });

        const files = await Promise.all(filePromises);
        
        files.forEach((file, index) => {
          formData.append(`${key}`, file);
        });
        
      } else {
        formData.append(key, value);
      }
    })
  );

  return api.post('/api/ask/ask/update', formData
  , {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


const comment = async (param: AskSaveForm) => {
  const formData = new FormData();

  await Promise.all(
    Object.entries(param).map(async ([key, value]) => {
      if (key === 'uploadFiles'  && Array.isArray(value) && value.length > 0) {

        // switchBase64 함수를 사용하여 이미지 파일을 처리
        const updatedUploadFiles = await switchBase64(value);

        const filePromises = updatedUploadFiles.map(async (url, index) => {
          const response = await fetch(url);
          const blob = await response.blob();

          return new File([blob], `file${index + 1}.png`, { type: 'image/png' });
        });

        const files = await Promise.all(filePromises);
        
        files.forEach((file, index) => {
          formData.append(`${key}`, file);
        });
        
      } else {
        formData.append(key, value);
      }
    })
  );

  return api.post('/api/ask/ask/comment', formData
  , {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


const deleteAsk = (params: AskParams) => {
  const formData = new FormData();
  if(params.id) formData.append('id', params.id);

  return api.post('/api/ask/ask/delete', formData);
}



//base64 인코딩이 아니면 enode
const switchBase64 = async (uploadFiles: any) => {
  const updatedUploadFiles = [];
  
  for (const url of uploadFiles) {
    if (url.startsWith('/api/file/')) {// 주어진 값이 '/api/file/'로 시작하는 경우
      const response = await fetch(url);// 해당 경로에 있는 파일을 가져와서 Base64로 인코딩
      const blob = await response.blob();
      const reader = new FileReader();
      
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64Data = reader.result;
        updatedUploadFiles.push(base64Data);
      };
    } else {
      if(!url.startsWith('data:image')) {
        alert("!url.startsWith('data:image')")
      }else {
        updatedUploadFiles.push(url);
      }
    }
  }

  return updatedUploadFiles;
};


 



const CommerceAPI = { list, save, detail, update, comment };

export default CommerceAPI;
