import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import VisualSlide from "../../VisualSlide";
import FadeSlider2 from '../../FadeSlider2';
import EditorComponent from '../../QuillEditor';
import BoardAPI from '../../../apis/BoardAPI';
import { BoardSaveForm } from '../../../types/types';
import { RootState } from '../../../redux/reducers/RootReducer';
import { useSelector } from 'react-redux';
import useAuthorization from '../../../hooks/useAuthorization';



const slides = [VisualSlide];
const SLIDE_TEXT = "Community";

const CommunitySaveForm = () => {

  const navigate = useNavigate()
  const [editorTitle, setEditorTitle] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [editorImg, setEditorImg] = useState<string[]>([]);
  const userState = useSelector((state: RootState) => state.user);  

  const handleAuth = useAuthorization();

  //등록 버튼
  const submitPost = () => {
    if(handleAuth){
      const data: BoardSaveForm = {
        title: editorTitle !== '' ? editorTitle : undefined,
        content: (editorContent !== '' && editorContent !== '<p><br></p>') ? editorContent : undefined,
        uploadFiles: editorImg.length > 0 ? editorImg : undefined,
      };
      console.log(data)
      BoardAPI.save(data).then((res) => {
        alert('게시글이 등록되었습니다.')
        navigate('/COMMUNITY/'+ res.data, { replace : true })
        //성공 시 detail페이지 이동, state 초기화
      }).catch((e) => {
        alert("error 발생: " + e)
      });
    }
  };

  //뒤로가기 버튼클릭
  const prevBtn = () => {
    if((editorTitle || editorContent) && editorContent !== '<p><br></p>') {
      if(window.confirm('내용이 저장되지 않았습니다. 정말 돌아가시겠습니까?')){
        navigate(-1)
      }
    }else{
      navigate(-1)
    }
  }

  return (
    <div>
      <FadeSlider2 components={slides} text={SLIDE_TEXT} />
      <div className="container form-container mt-5 mb-5" style={{ maxWidth: '1600px', margin: '0 auto' }}>
        <div className="d-flex justify-content-between mt-2">
          <button type="button" className="btn btn-secondary mb-5" onClick={prevBtn}>뒤로가기</button>
          <button type="button" className="btn btn-primary mb-5" onClick={submitPost}>게시글 등록</button>
        </div>
        
        <label>제목</label>
        <input onChange={(e) => setEditorTitle(e.target.value)} type="text" className="form-control mb-3" id="postTitle" placeholder="게시글 제목을 입력하세요" />
        <label>내용</label>
        <EditorComponent onContentChange={setEditorContent} onImgChange={setEditorImg} />
      </div>

    </div>
  );
};

export default CommunitySaveForm;
