// userSlice.ts

import { createSlice } from '@reduxjs/toolkit';
import { UserState } from '../../types/types';
import { userAsyncActions } from './../actions/userAsyncsActions';

const initUserState: UserState = {
  user: {
    username: '',
    nickname: '',
    status: '',
    roles: '',
    gender: '',
    telno: '',
    birth: '',
    email: '',
    address: '',
    detailAddress: '',
    filePath: '',
  },
    dataLoad: false,
    isLogin: false,
    error: '',
}

interface Error {
  message?: string,
}

const userSlice = createSlice({
  name: 'user',
  initialState: initUserState,
  reducers: {
    setUser: (state, action) => action.payload,
    clearUser: (state) => initUserState,
  },
  
  extraReducers: (builder) => {
    builder.addCase(userAsyncActions.fetchUser.pending, (state, action) => {//회원정보 조회
      state.user = initUserState.user;
    })
    builder.addCase(userAsyncActions.fetchUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.dataLoad = true;
      state.isLogin = action.payload.roles !== ''
      state.error = '';
    })
    builder.addCase(userAsyncActions.fetchUser.rejected, (state, action) => {
      state.user = initUserState.user;
      state.dataLoad = true;
    });
    

    builder.addCase(userAsyncActions.login.pending, (state, action) => {//로그인
      state.user = initUserState.user;
    })
    builder.addCase(userAsyncActions.login.fulfilled, (state, action) => {
      state.user = action.payload;
      state.dataLoad = true;
      state.isLogin = action.payload.roles !== ''
      state.error = '';
    })
    builder.addCase(userAsyncActions.login.rejected, (state, action) => {
      state.dataLoad = true;
      const derror: Error = action.error;
      state.error = derror.message? derror.message: '';
      alert(derror.message? derror.message: '')
    });

    
    builder.addCase(userAsyncActions.logout.fulfilled, (state, action) => {//로그아웃
      state.user = initUserState.user;
      state.dataLoad = true;
      state.isLogin = false;
    });
    

    builder.addCase(userAsyncActions.quit.pending, (state, action) => {//회원탈퇴
      // state.user = initUserState.user;
    })
     builder.addCase(userAsyncActions.quit.fulfilled, (state, action) => {
      // state.user = initUserState.user;
      state.dataLoad = true;
      // state.isLogin = false;
      state.error = '';
    })
    builder.addCase(userAsyncActions.quit.rejected, (state, action) => {
      state.dataLoad = true;
      const derror: Error = action.error;
      state.error = derror.message? derror.message: '';
      alert(derror.message? derror.message: '')
    });
  },
});

export const dispatch = userSlice.actions;

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
