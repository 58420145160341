import React, { useState, useEffect, ReactElement } from "react";
import ScrollArrow from "./ScrollArrow";

interface FadeSliderProps {
  components: React.FC<{ active: boolean }>[];
}

const FadeSlider = (props: FadeSliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (props.components.length > 1) {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % props.components.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [props.components.length]);

  return (
    <div className="fade-slider">
      <ScrollArrow />
      {props.components.map((Component, index) => (
        <div
          key={index}
          className={`slide ${ index === currentSlide ? "fade-in" : "fade-out"}`}>
          <Component active={index === currentSlide} />
        </div>
      ))}
    </div>
  );
};

export default FadeSlider;
