import { useState } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {MenuDeps} from '../types/types'

import { useSpring, animated } from "react-spring";
import { useNavigate } from "react-router-dom";

interface AccordionProps {
    title: string;
    link: string;
    menuDeps: MenuDeps;
    hamClick: () => void;
}

const Accordion = (props: AccordionProps) => {

  const MENULENGTH = props.menuDeps.MENU_ITEM.length;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [hasChild, setHasChild] = useState(MENULENGTH > 0);
  const wrapper = document.getElementById('wrapper');

  //여닫기
  const toggleMenu = () => {
    setOpen(!open);
  };
  //이동
  const handleHeader = (e: string) => {
    wrapper?.scrollTo({ top: 0, behavior: 'smooth'});
    props.hamClick();
    navigate('/' + e)
  }

  let maxheight = 42 + (42 * MENULENGTH) + "px";

  if(window.innerWidth > 600) {/** 반응형 */
    maxheight = 47 + (47 * MENULENGTH) + "px";
  }

  const styles = {
    accordionTitle: {
      color: open ? "#000000" : "#000000",//검정색
    }
  };

  const openAnimation = useSpring({
    from: {
        opacity: 0,
        maxHeight: '40px',
    },
    to: {
        opacity: 1,
        maxHeight: open? maxheight : '40px',
    },
    config: { duration: 100 }
  });

  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
      color: "#000000"
    },
    to: {
      transform: open ? "rotate(180deg)" : "rotate(0deg)",
      color: open ? "#000000" : "#000000"
    },
    config: { duration: 30 }
  });


  return (
    <animated.div className="accordion__item" style={openAnimation}>
      {/* <div className="accordion__header" onClick={hasChild? toggleMenu: handleHeader}> */}
      <div className="accordion__header" onClick={() => hasChild ? toggleMenu() : handleHeader(props.link)}>
        <h4 style={styles.accordionTitle}>{props.title}</h4>
        {hasChild && 
          <animated.i style={iconAnimation}>
            <ExpandMoreIcon />
          </animated.i>
        }
      </div>
      <ul className="menu-ul">
        {props.menuDeps.MENU_ITEM.map((menuItem, index) => (
          <li className="menu-ele" key={index} onClick={() => handleHeader(menuItem.LINK)}>
            <a className="accordion__content" style={{ color: '#000000'}}>
              {menuItem.NAME}
            </a>
          </li>
        ))}
      </ul>
    </animated.div>
  );
}

export default Accordion;
