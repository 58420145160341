import { AxiosError } from "axios";
import { useRef, useState, useMemo, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EditorComponentProps } from "../types/types";



const EditorComponent: React.FC<EditorComponentProps> = ({ onContentChange, onImgChange: setEditorImage, imgs: editorImgs, injectContent: injectContent }) => {
  const QuillRef = useRef<ReactQuill>();
  const [contents, setContents] = useState(injectContent != undefined ? injectContent : '');


  useEffect(() => {
    if (onContentChange) {
      onContentChange(contents);
      const imgs = extractImageValues(contents);
      if (editorImgs !== imgs) {
        setEditorImage(imgs);
      }
    }
  }, [contents, onContentChange]);

  useEffect(() => {
    const fetchData = async () => {
      if (injectContent) {
        const htmlContent = await mapHtmlContent(injectContent);
        setContents(htmlContent);
      }
    };

    fetchData(); // 비동기 함수 호출
  }, [injectContent]);


  //IMG 변경 시 작동 Event Handler
  const imageHandler = () => {
    const input = document.createElement("input");

    input.classList.add("custom-file-input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async (event) => {
      const file = input.files;
      if (event && file !== null && file.length > 0) {
        const selectedFile = file[0];
        const reader = new FileReader();

        reader.onload = (event) => {
          if (event && event.target) {
            const dataURL = event.target.result as string;

            try {
              const range = QuillRef.current?.getEditor().getSelection()?.index;
              if (range !== null && range !== undefined) {
                let quill = QuillRef.current?.getEditor();
                quill?.setSelection(range, 1);
                quill?.clipboard.dangerouslyPasteHTML(
                  range,
                  `<img src="${dataURL}" />`
                );
              }
            } catch (error) {
              const err = error as AxiosError;
              console.log(err);
            }
          }
        };

        reader.readAsDataURL(selectedFile);
      }
    };
  };

  // 이미지 SRC 추출
  const extractImageValues = (htmlContent: string) => {

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    const imageValues: string[] = [];
    const imgElements = tempDiv.getElementsByTagName('img');

    for (let i = 0; i < imgElements.length; i++) {
      const src = imgElements[i].getAttribute('src');
      if (src) {
        imageValues.push(src);
      }
    }

    return imageValues;
  };


  //injectContent로 데이터 주입 시 html소스에 있는 url형태의 img 데이터 base64로 다운로드
  const mapHtmlContent = async (htmlContent: string) => {
    const imageValues: string[] = [];

    //임시객체생성
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    //url 추출
    const imgElements = tempDiv.getElementsByTagName('img');
    for (let i = 0; i < imgElements.length; i++) {
      const src = imgElements[i].getAttribute('src');
      if (src) {
        imageValues.push(src);
      }
    }

    // 이미지를 다운로드하고 url 형태의 파일을 Base64 문자열로 변환하여 img 태그에 설정
    await Promise.all(imageValues.map(async (src) => {
      try {
        const response = await fetch(src);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        await new Promise<void>((resolve) => {
          reader.onloadend = function () {
            const base64data = reader.result;
            if (base64data && typeof base64data === 'string') {
              tempDiv.innerHTML = tempDiv.innerHTML.replace(src, base64data);
            }
            resolve();
          };
        });
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }));
    return tempDiv.innerHTML;
  };

  //QuillEditor 설정 함수
  const config = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: ["small", false, "large", "huge"] }, { color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] },
          ],
          ["image"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-12">
          <ReactQuill
            ref={(element) => {
              if (element !== null) {
                QuillRef.current = element;
              }
            }}
            value={contents}
            onChange={setContents}
            modules={config}
            theme="snow"
            placeholder="내용을 입력해주세요."
          />
        </div>
        <div className="col-md-6 col-12">
          <h3>Preview</h3>
          <div
            className="ql-editor preview"
            dangerouslySetInnerHTML={{ __html: contents }}
          />
        </div>
      </div>
    </>
  );
};

export default EditorComponent;
