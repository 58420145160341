// userAsyncActions.js 비동기 액션 정의

import axios from 'axios';
import UserAPI from '../../apis/UserAPI';
import AuthenticationAPI from '../../apis/AuthenticationAPI';

import { applyMiddleware, createAsyncThunk } from '@reduxjs/toolkit';
import { Authentication, JoinForm, UserState } from '../../types/types';

const initUserState: UserState = {
  user: {
    username: '',
    nickname: '',
    status: '',
    roles: '',
    gender: '',
    telno: '',
    birth: '',
    email: '',
    address: '',
    detailAddress: '',
    filePath: '',
  },
  dataLoad: false,
  isLogin: false,
  error: '',
}


/** 회원정보 조회 */
export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async () => {
    return await UserAPI.me2();;
  });

/** 로그인 */
export const login = createAsyncThunk(
  'user/login',
  async (param: Authentication) => {
    return await AuthenticationAPI.login(param)
      .then((response) => {
        return UserAPI.me2();
      }).catch((error) => {
        throw error.response.data.message;
      });
  });

/** 로그아웃 */
export const logout = createAsyncThunk(
  'user/logout',
  async () => {
    return await AuthenticationAPI.logout();
  });

/** 회원가입 */
export const join = createAsyncThunk(
  'user/join',
  async (param: JoinForm) => {
    return await UserAPI.join(param)
      .then((response) => {
        return UserAPI.me2();
      }).catch((error) => {
        throw error.response.data.message;
      })
  }
);

/** 회원가입2 */
export const join2 = createAsyncThunk(
  'user/join',
  async (param: JoinForm, ThunkAPI) => {
    return await UserAPI.join(param)
      .then((response) => {
        return UserAPI.me2();
      }).catch((error) => {
        return ThunkAPI.rejectWithValue(error.response.data);
      })
  }
);

/* 회원정보 수정 */
export const update = createAsyncThunk(
  'user/update',
  async (param: JoinForm, ThunkAPI) => {
    return await UserAPI.update(param)
      .catch((error) => {
        return ThunkAPI.rejectWithValue(error.response.data);
      })
  }
);

/* 회원탈퇴 */
export const quit = createAsyncThunk(
  'user/quit',
  async (param: JoinForm, ThunkAPI) => {
    return await UserAPI.quit(param)
      .catch((error) => {
        return ThunkAPI.rejectWithValue(error.response.data);
      })
  }
);

/** 카카오 로그인 */
/** 로그아웃 */
export const kakaoLogin = createAsyncThunk(
  'user/kakaoLogin',
  async () => {
    return await AuthenticationAPI.KaKaoLogin();
  });



export const userAsyncActions = { fetchUser, login, logout, join, join2, kakaoLogin, quit }