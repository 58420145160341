import { useEffect, useState } from "react";
// import visual2 from '../assets/bf1/2.jpg';
import visual2s from '../assets/ppt/02-1.jpg';
import visual2 from '../assets/format/KakaoTalk_20231021_020448195.jpg';

const Slide2: React.FC<{ active: boolean }> = ({active}) => {
  const [showFirTxt, setShowFirTxt] = useState(false);

  useEffect(() => {
    if(active){
      setTimeout(() => {
        setShowFirTxt(true);
      }, 500);

      setTimeout(() => {
        setShowFirTxt(false);
      }, 4000);
    }
  }, [active])

  return (
    <div className="slide-inner">
      <img className="video_responsive" src={visual2}/>
      {/* <img className="video_responsive" src='https://source.unsplash.com/random'/> */}
      
      <div className="txt-wrap">
        <div className="txt-ani-fir">
          <p className={showFirTxt ? "txt-act-fir" : "txt-inact-fir"}>
            <span className="txt-con-fir">헬스 트렌드 속으로: 최신 헬스와 윌니스 소식</span>
          </p>
        </div>
        <div className="txt-ani-fir">
          <p className={showFirTxt ? "txt-act-fir" : "txt-inact-fir"}>
            <span className="txt-con-fir">우리의 목표는 더 나은 건강을 추구하는 것</span>
          </p>
        </div>
        <div className="txt-ani-sec">
          <p className={showFirTxt ? "txt-act-sec" : "txt-inact-sec"}>
            <span className="txt-con-sec">SATISFACTION</span>
          </p>
        </div>
      </div>

    </div>
  );
};

export default Slide2;

{/* <video
        className="video_responsive"
        autoPlay
        muted
        loop
        src="https://framerusercontent.com/modules/assets/Ah61VZSEziQYKEgtGidGHRQ4kIY~l0CGcg8ExkWKF4VmnZB_feFaCZrPYN9VKzvK39_NV1k.mp4"
      /> */}