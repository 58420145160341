import React, { useEffect, useState } from "react";
import Dummy from "../Dummy";
import BoardAPI from '../../apis/BoardAPI';
import FadeSlider2 from "../FadeSlider2";
import VisualSlide from "../VisualSlide";
import { AskItem, CommerceResponse, GET_PRODUCT_URL, ProductContent, initStoreParams, Page, StoreItem } from "../../types/types";
import StoreAPI from "../../apis/CommerceAPI";
const ProductStore = () => {

  const slides = [VisualSlide];
  const SLIDE_TEXT = "Product";

  //list search
  const [page, setPage] = useState(1);
  const [searchType, setSearchType] = useState('all');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [commerceResponse, setCommerceResponse] = useState<CommerceResponse | null>(null);
  const [params, setParams] = useState(initStoreParams);




  useEffect(() => {
    load()
  }, []);


  //목록조회 API
  const load = (pageNumber?: number) => {
    const updatedParams = {
      ...params,
      page: pageNumber !== undefined ? pageNumber : params.page
    };

    console.log('sendParams: ', updatedParams);

    StoreAPI.list(updatedParams)
      .then((res) => {
        console.log('response: ', res);
        setCommerceResponse(res.data);
      })
      .catch((error) => {
        console.log('AskAPI-E: ', error.response?.data?.message);
        setCommerceResponse(null);
      });
  };

  const itemClick = (item: ProductContent) => {
    const productId = item.channelProducts[0].channelProductNo
    const url = GET_PRODUCT_URL + productId
    window.open(url,'_blank')
  }


  return (
    <div>
      <FadeSlider2 components={slides} text={SLIDE_TEXT} />
      <div className="cmm-container">

        <div className="container mt-5">
          <div className="d-flex justify-content-center align-items-center my-5">
            <span className="col-4 border-bottom mx-2"></span>
            <h1 className="col-4 text-center my-0">Product</h1>
            <span className="col-4 border-bottom mx-2"></span>
          </div>
        </div>

        <div className="container mb-3">
          <div className="grid-container" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '16px' }}>
            {commerceResponse && commerceResponse.contents.length > 0 ?
              <>
                {commerceResponse.contents.map((item, index) => (
                  <div className="card store-item" onClick={() => itemClick(item)}>
                    <img src={item.channelProducts[0].representativeImage.url} className="card-img-top" alt="상품 이미지 1"
                      style={{ maxHeight: "250px", objectFit: 'cover' }} />
                    <div className="card-body">
                      <h5 className="card-title">{item.channelProducts[0].name}</h5>
                      {/* <p className="card-text">₩ 가격1</p> */}
                      <p className="card-text">₩ {item.channelProducts[0].discountedPrice}</p>
                    </div>
                  </div>
                ))}
              </>
              : null}

          </div>
        </div>

      </div>
    </div>
  );
};

export default ProductStore;


{/* 가로 3개 짜리 
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4 mb-4">
              <div className="card">
                <img src="https://media.istockphoto.com/photos/fried-egg-with-bacon-in-a-white-plate-with-fried-pieces-of-bread-picture-id1079584712?k=20&m=1079584712&s=170667a&w=0&h=JuK4Je9_SHk3jZXYeifSVRvutE3CKih-_iRfH8T0Kb4=" className="card-img-top" alt="상품 이미지 1" />
                <div className="card-body">
                  <h5 className="card-title">상품명 1</h5>
                  <p className="card-text">₩ 가격1</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card">
                <img src="https://media.istockphoto.com/photos/fried-egg-with-bacon-in-a-white-plate-with-fried-pieces-of-bread-picture-id1079584712?k=20&m=1079584712&s=170667a&w=0&h=JuK4Je9_SHk3jZXYeifSVRvutE3CKih-_iRfH8T0Kb4=" className="card-img-top" alt="상품 이미지 2" />
                <div className="card-body">
                  <h5 className="card-title">상품명 2</h5>
                  <p className="card-text">₩ 가격2</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card">
                <img src="https://media.istockphoto.com/photos/fried-egg-with-bacon-in-a-white-plate-with-fried-pieces-of-bread-picture-id1079584712?k=20&m=1079584712&s=170667a&w=0&h=JuK4Je9_SHk3jZXYeifSVRvutE3CKih-_iRfH8T0Kb4=" className="card-img-top" alt="상품 이미지 3" />
                <div className="card-body">
                  <h5 className="card-title">상품명 3</h5>
                  <p className="card-text">₩ 가격3</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
{/* 그냥 list
            <div className="card">
              <img src="https://media.istockphoto.com/photos/fried-egg-with-bacon-in-a-white-plate-with-fried-pieces-of-bread-picture-id1079584712?k=20&m=1079584712&s=170667a&w=0&h=JuK4Je9_SHk3jZXYeifSVRvutE3CKih-_iRfH8T0Kb4=" className="card-img-top" alt="상품 이미지 1" />
              <div className="card-body">
                <h5 className="card-title">상품명 1</h5>
                <p className="card-text">₩ 가격1</p>
              </div>
            </div>
            <div className="card">
              <img src="https://media.istockphoto.com/photos/fried-egg-with-bacon-in-a-white-plate-with-fried-pieces-of-bread-picture-id1079584712?k=20&m=1079584712&s=170667a&w=0&h=JuK4Je9_SHk3jZXYeifSVRvutE3CKih-_iRfH8T0Kb4=" className="card-img-top" alt="상품 이미지 2" />
              <div className="card-body">
                <h5 className="card-title">상품명 2</h5>
                <p className="card-text">₩ 가격2</p>
              </div>
            </div>
            <div className="card">
              <img src="https://media.istockphoto.com/photos/fried-egg-with-bacon-in-a-white-plate-with-fried-pieces-of-bread-picture-id1079584712?k=20&m=1079584712&s=170667a&w=0&h=JuK4Je9_SHk3jZXYeifSVRvutE3CKih-_iRfH8T0Kb4=" className="card-img-top" alt="상품 이미지 3" />
              <div className="card-body">
                <h5 className="card-title">상품명 3</h5>
                <p className="card-text">₩ 가격3</p>
              </div>
            </div>
            <div className="card">
              <img src="https://media.istockphoto.com/photos/fried-egg-with-bacon-in-a-white-plate-with-fried-pieces-of-bread-picture-id1079584712?k=20&m=1079584712&s=170667a&w=0&h=JuK4Je9_SHk3jZXYeifSVRvutE3CKih-_iRfH8T0Kb4=" className="card-img-top" alt="상품 이미지 4" />
              <div className="card-body">
                <h5 className="card-title">상품명 4</h5>
                <p className="card-text">₩ 가격4</p>
              </div>
            </div>
            <div className="card">
              <img src="https://media.istockphoto.com/photos/fried-egg-with-bacon-in-a-white-plate-with-fried-pieces-of-bread-picture-id1079584712?k=20&m=1079584712&s=170667a&w=0&h=JuK4Je9_SHk3jZXYeifSVRvutE3CKih-_iRfH8T0Kb4=" className="card-img-top" alt="상품 이미지 4" />
              <div className="card-body">
                <h5 className="card-title">상품명 4</h5>
                <p className="card-text">₩ 가격4</p>
              </div>
            </div> */}