import axios, { AxiosResponse } from "axios";
import { Authentication } from "../types/types";
import api from "./api";

/** 로그인 */
const login = (param: Authentication): Promise<AxiosResponse> => {
  const formData = new FormData();
  Object.entries(param).forEach(([key, value]) => {
    formData.append(key, value);
  });
  
  return api.post('/login', formData);
}

/** 로그아웃 */
const logout = (): Promise<void> => {
  return api.get('/user/logout2');
}

/** KAKAO 로그인 */
const KaKaoLogin = (): Promise<void> => {
  return api.get('/oauth2/authorization/kakao', {
    withCredentials: true,
  });
}




const AuthenticationAPI = { login, logout, KaKaoLogin };
export default AuthenticationAPI;