import fakelogo from '../assets/fake-logo2.png';
import { useState } from "react";
import Accordion from './Accordion';
import { YOONSKINMENU } from "../types/types";
import { title } from 'process';
import { useNavigate } from 'react-router-dom';


type SideBarProps = {
  isLogin: boolean;
  isOpen: boolean;
  hamClick: () => void;
};

const SideBar = (param: SideBarProps): JSX.Element => {

  const [menu, setMenu] = useState('');
  const navigate = useNavigate();
  const wrapper = document.getElementById('wrapper');

  const handleMenuChange = (e: any) => {
    const inner = e.target.innerText;
    if (inner === menu) {
      setMenu('');
    } else {
      setMenu(inner);
    }
  }

  //로고 clickEvent
  const navigateAndPopupClose = (path: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    param.hamClick();
    event.preventDefault();
    navigate(path)
    wrapper?.scrollTo({ top: 0, behavior: 'smooth'});
  }
  
  const logoClick = () => {
    navigate('/')
    param.hamClick();
    wrapper?.scrollTo({ top: 0, behavior: 'smooth'});
  }

    


  return (
    <div className={`Poup_mobile_menu ${param.isOpen ? 'on' : ''}`} >
      <div className="mobile_menu_bg" onClick={param.hamClick}></div>
      <div className="mobile_menu_cont">
        <div className="mobile_title">
          <a onClick={logoClick} className="logo">
            <img src={fakelogo} alt="윤스킨 웰니스" />
          </a>
          <button type="button" className="mobile_close" onClick={param.hamClick}>
            <span className="sound_only">모바일메뉴닫기</span>
          </button>
        </div>
        <div className="mobile_login">
          <div className={`mobile_login_guest ${param.isLogin ? 'dis-no' : ''}`}>
            <a onClick={navigateAndPopupClose('/login')} href="#" className="txt_blue">로그인</a>
            <a onClick={navigateAndPopupClose('/join')}>회원가입</a>
          </div>
          <div className={`mobile_login_member ${param.isLogin ? '' : 'dis-no'}`}>
            <a onClick={navigateAndPopupClose('/myPage')} className="txt_blue">마이페이지</a>
            <a onClick={navigateAndPopupClose('/logout')}>로그아웃</a>
          </div>
        </div>
        <div id="mobile_gnb">
          {Object.keys(YOONSKINMENU).map((key, index) => (
            // <Accordion
            //   title={key}
            //   key={'accId' + index}
            //   hamClick={param.hamClick}
            //   link={YOONSKINMENU[key as keyof typeof YOONSKINMENU].LINK}
            //   menuItems={YOONSKINMENU[key as keyof typeof YOONSKINMENU]}
            // />
            <Accordion
              key={'accId' + index}
              title={key}
              hamClick={param.hamClick}
              link={YOONSKINMENU[key].LINK}
              menuDeps={YOONSKINMENU[key]}
            />
          ))}
        </div>
        <div className="mobile_info">
          <a href="#" className="txt_blue">개인정보 처리방침</a>
          <a href="#">이용약관</a>
        </div>
      </div>
    </div>
  );
}

export default SideBar;

/**
 * <ul className="mobile_gnb_dep1">
                        <li className={`mobile_gnb_dep1_li `} >
                            <a onClick={handleMenuChange} >INTRODUCE</a>
                        </li>
                        <li className={`mobile_gnb_dep1_li mgd_li_${menu === 'BUSINESS' ? 'drop' : 'link'}`} >
                            <a onClick={handleMenuChange}>BUSINESS</a>
                            <ul className={`mobile_gnb_dep2 ${menu === 'BUSINESS' ? '' : 'act'}`}>
                                <li>
                                    <a href="#">menu1</a>
                                </li>
                                <li>
                                    <a href="#">menu2</a>
                                </li>
                                <li>
                                    <a href="#">menu3</a>
                                </li>
                                <li>
                                    <a href="#">menu4</a>
                                </li>
                                <li>
                                    <a href="#">menu5</a>
                                </li>
                                <li>
                                    <a href="#">menu6</a>
                                </li>
                            </ul>
                        </li>
                        <li className={`mobile_gnb_dep1_li mgd_li_${menu === 'PRODUCT' ? 'drop' : 'link'}`} >
                            <a onClick={handleMenuChange} >PRODUCT</a>
                            <ul className={`mobile_gnb_dep2 ${menu === 'PRODUCT' ? '' : 'dis-no'}`}>
                                <li>
                                    <a href="#">상품카테고리</a>
                                </li>
                            </ul>
                        </li>
                        <li className={`mobile_gnb_dep1_li mgd_li_${menu === 'COMMUNITY' ? 'drop' : 'link'}`} >
                            <a onClick={handleMenuChange} >COMMUNITY</a>
                            <ul className={`mobile_gnb_dep2 ${menu === 'COMMUNITY' ? '' : 'dis-no'}`}>
                                <li>
                                    <a href="#">상품카테고리</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
 */