// reducers/exceptionSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  code: 0, // 예외 코드를 저장할 상태
};

const exceptionSlice = createSlice({
  name: 'exception',
  initialState,
  reducers: {
    setExceptionStatus: (state, action) => {
      state.code = action.payload;
    },
  },
});

export const { setExceptionStatus } = exceptionSlice.actions;

export default exceptionSlice.reducer;
