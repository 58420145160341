import { useEffect, useState } from "react";
import mainsec1 from '../assets/main-sect-natural-img01.png';
import mainsec2 from '../assets/main-sect-natural-img02.png';
import mainsec3 from '../assets/main-sect-natural-img03.png';

const CardSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleCard, setIsVisibleCard] = useState(false);

    useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".main-sect-natural");
      if (!section) return;

      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const threshold = windowHeight / 1.3;

      if (rect.top <= threshold ) {
        setIsVisible(true);
        setIsVisibleCard(true);
      } else {
        setIsVisible(false);
        setIsVisibleCard(false);
      }
    };
    document.getElementById('wrapper')?.addEventListener("scroll", handleScroll);
    return () => {
      document.getElementById('wrapper')?.removeEventListener("scroll", handleScroll);
    };
  }, []); 

  useEffect(() => {
    const cardElements = document.querySelectorAll(".card-ele");
    if(isVisible){
      cardElements.forEach((element, index) => {
        setTimeout(() => {
          element.classList.add("fadeinup");
        }, 200 * index);
      });
    }else{
      cardElements.forEach((element) => {
        element.classList.remove("fadeinup");
      });
    }
  }, [isVisibleCard]);


  return (

    <section className="main-sect-natural">
      <article className="container-fluid">

        <div className="titlebox">
          <p className="subtit">NATURAL BEAUTY EXCLUSIVELY <br className="mo-only" /> FOR YOUR BODY</p>
          <div className="subt-wrap">
            <div className="subt-ani">
              <p className={ isVisible ? "subt-act": "subt-inact"}>
                <span className="subt-con">오직 나만을 위한 자연스러운 아름다움,</span>
              </p>
            </div>
          </div>
        </div>

        <div className="cont-wrap ">
          <div className="natural-cont">
            <article className="card-ele">
              <div className="card-ani">
                <div className="img_box">
                  <img src={mainsec1} alt="Total Solution 이미지" className="img-fluid" />
                </div>
                <div className="ctit-wrap">
                  <span className="ctit">Profiling</span>
                </div>
                <p className="ccon">
                  피부관련 상담, 운동관련 상담, <br />
                  생활습관 및 개선 해야할  <br />
                  방향성 제시 및 구체적인 목표와 <br />
                  계획수립
                </p>
              </div>
            </article>
            <article className="card-ele">
              <div className="card-ani">
                <div className="img_box">
                  <img src={mainsec2} alt="Total Solution 이미지" className="img-fluid" />
                </div>
                <div className="ctit-wrap">
                  <span className="ctit">Satisfaction</span>
                </div>
                <p className="ccon">
                  서비스의 본질 <br />
                  "당신의 니즈"를  <br />
                  명확하게 충족시켜드립니다.
                </p>
              </div>
            </article>
            <article className="card-ele">
              <div className="card-ani">
                <div className="img_box">
                  <img src={mainsec3} alt="Total Solution 이미지" className="img-fluid" />
                </div>
                <div className="ctit-wrap">
                  <span className="ctit">Acheivement</span>
                </div>
                <p className="ccon">
                  저를 찾아와주신 <br />
                  고객들의 목표달성이 <br />
                  회사가 성장하는 길입니다.
                </p>
              </div>
            </article>
          </div>
        </div>
        {/* <div className="cont-wrap mo-only">
          <div className="swiper-container main-natural-slide swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
            <div className="swiper-wrapper gs_reveal active" >
              <div className="innerbox">
                <article className="natural-box">
                  <div className="img_box">
                    <img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-natural-img03.png" alt="Total Solution 이미지" className="img-fluid" />
                  </div>
                  <span className="notoserif">Result</span>
                  <p className="notosans">
                    피부의 특성과 노화 정도를 해부학적으로 <br />
                    접근해 1:1 맞춤 시술로 높아지는 결과 만족도
                  </p>
                </article>
              </div>
            </div>
            <div className="swiper-slide slide-motion" data-swiper-slide-index="0">
              <div className="innerbox">
                <article className="natural-box">
                  <div className="img_box">
                    <img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-natural-img01.png" alt="Total Solution 이미지" className="img-fluid" />
                  </div>
                  <span className="notoserif">Total Solution</span>
                  <p className="notosans">
                    리프팅, 피부부터 쁘띠, 바디까지 <br />
                    다양한 고민 해결이 가능한 토탈 뷰티 솔루션
                  </p>
                </article>
              </div>
            </div>
            <div className="swiper-slide slide-motion" data-swiper-slide-index="1">
              <div className="innerbox">
                <article className="natural-box">
                  <div className="img_box">
                    <img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-natural-img02.png" alt="Total Solution 이미지" className="img-fluid" />
                  </div>
                  <span className="notoserif">Specialist</span>
                  <p className="notosans">
                    풍부한 경험과 연구를 통한 노하우로 부작용은 <br className="pc-only" />
                    줄이고 시술 만족도는 높이는 피부 전문가
                  </p>
                </article>
              </div>
            </div>
            <div className="swiper-slide slide-motion" data-swiper-slide-index="2">
              <div className="innerbox">
                <article className="natural-box">
                  <div className="img_box">
                    <img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-natural-img03.png" alt="Total Solution 이미지" className="img-fluid" />
                  </div>
                  <span className="notoserif">Result</span>
                  <p className="notosans">
                    피부의 특성과 노화 정도를 해부학적으로 <br />
                    접근해 1:1 맞춤 시술로 높아지는 결과 만족도
                  </p>
                </article>
              </div>
            </div>
            <div className="swiper-slide slide-motion swiper-slide-duplicate" data-swiper-slide-index="0">
              <div className="innerbox">
                <article className="natural-box">
                  <div className="img_box">
                    <img src="https://www.skinbanobagi.com/theme/banobagi/img/main-sect-natural-img01.png" alt="Total Solution 이미지" className="img-fluid" />
                  </div>
                  <span className="notoserif">Total Solution</span>
                  <p className="notosans">
                    리프팅, 피부부터 쁘띠, 바디까지 <br />
                    다양한 고민 해결이 가능한 토탈 뷰티 솔루션
                  </p>
                </article>
              </div>
            </div></div>
          <div className="swiper-navigation main-natural-slide-navigation">
            <span className="swiper-prev" tabIndex={0} role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-717fc10b5607db9cf"></span>
            <span className="swiper-next" tabIndex={0} role="button" aria-label="Next slide" aria-controls="swiper-wrapper-717fc10b5607db9cf"></span>
          </div>
          <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div> */}
      </article>
    </section>

  );

}


export default CardSection;