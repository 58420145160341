const Footer = (): JSX.Element => {
  return (
    <footer>
          <h1 className="notext">푸터 영역</h1>
          <div id="footer">
                <div className="footer_link">
                    <a href="#" className="yellow">개인정보 처리방침</a>
                    <a href="#">이용약관</a>
                </div>
                <p>
                    주소 : 광주광역시 서구 유촌동 천변우하로 181<br/>
                    대표전화 : 062-601-1311 &nbsp; 팩스 : 062-601-1311<br/>
                    Copyright © GWANGJU CITY. ALL RIGHT RESERVED.
                </p>
          </div>
          {/* <div id='footer_mobile'>
            <ul>
              <li>
                <a href="#">
                  <div>
                    <img src="../assets/ic_footer01.png" alt="소통마당 검정" />
                  </div>
                  <p>소통마당</p>
                </a>
              </li>
              <li>
                <a href="#">
                  <div>
                    <img src="../assets/ic_footer01.png" alt="소통마당 검정" />
                  </div>
                  <p>참여마당</p>
                </a>
              </li>
              <li>
                <a href="#">
                  <div>
                    <img src="../assets/ic_footer01.png" alt="소통마당 검정" />
                  </div>
                  <p>홈</p>
                </a>
              </li>
              <li>
                <a href="#">
                  <div>
                    <img src="../assets/ic_footer01.png" alt="소통마당 검정" />
                  </div>
                  <p>정보마당</p>
                </a>
              </li>
              <li>
                <a href="#">
                  <div>
                    <img src="../assets/ic_footer01.png" alt="소통마당 검정" />
                  </div>
                  <p>마이페이지</p>
                </a>
              </li>
            </ul>
          </div> */}
        </footer>
  );
}

export default Footer;