import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { logout } from '../../redux/actions/userAsyncsActions';

const Logout = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();
  
  useEffect(() => {
    dispatch(logout());

    setTimeout(() => {
      window.location.href = '/';
    }, 1);
  }, [dispatch]);

  return (
    <></>
  );
};

export default Logout;
