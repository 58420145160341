import axios, { AxiosResponse } from "axios";
import api from './api'
import { CommunityParams, BoardSaveForm } from "../types/types";


const boardList = (params: CommunityParams) => {
  params.page = params.page-1;
  return api.get('/api/board/boards?sort=boardId,DESC', { params });
}

const detail = (params: CommunityParams) => {
  return api.get('/api/board/board', { params });
}

// const save = (param: BoardSaveForm) => {
//   const formData = new FormData();
//   Object.entries(param).forEach(([key, value]) => {
//     formData.append(key, value);
//   });
//   return api.post('/api/board/board/save', formData);
// }

// const save = (param: BoardSaveForm) => {
//   const formData = new FormData();

//   Object.entries(param).forEach(([key, value]) => {
//     if (key === 'uploadFiles' && Array.isArray(value) && value.length > 0 ) {
//       value.forEach((file, index) => {
//         //해당 로직에서 file값은 string 형태의 src 이다 이 값을 가지고 file을 만들어줘
//       });
//     } else {
//       formData.append(key, value);
//     }
//   });

//   return api.post('/api/board/board/save', formData);
// };

const save = async (param: BoardSaveForm) => {
  const formData = new FormData();

  param.content = removeSrc(param.content)//img src 지우기

  await Promise.all(
    Object.entries(param).map(async ([key, value]) => {
      if (key === 'uploadFiles' && Array.isArray(value) && value.length > 0) {
        const filePromises = value.map(async (url, index) => {
          const response = await fetch(url);
          const blob = await response.blob();

          return new File([blob], `file${index + 1}.png`, { type: 'image/png' });
        });

        const files = await Promise.all(filePromises);
        
        files.forEach((file, index) => {
          formData.append(`${key}`, file);
        });
        
      } else {
        formData.append(key, value);
      }
    })
  );

  return api.post('/api/board/board/save', formData
  , {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const update = async (param: BoardSaveForm) => {
  const formData = new FormData();

  //img src 지우기
  param.content = removeSrc(param.content)
  
  await Promise.all(
    Object.entries(param).map(async ([key, value]) => {
      if (key === 'uploadFiles'  && Array.isArray(value) && value.length > 0) {

        // switchBase64 함수를 사용하여 이미지 파일을 처리
        const updatedUploadFiles = await switchBase64(value);

        const filePromises = updatedUploadFiles.map(async (url, index) => {
          const response = await fetch(url);
          const blob = await response.blob();

          return new File([blob], `file${index + 1}.png`, { type: 'image/png' });
        });

        const files = await Promise.all(filePromises);
        
        files.forEach((file, index) => {
          formData.append(`${key}`, file);
        });
        
      } else {
        formData.append(key, value);
      }
    })
  ); 

  return api.post('/api/board/board/update', formData
  , {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


const deleteBoard = (params: CommunityParams) => {
  const formData = new FormData();
  if(params.id) formData.append('id', params.id);

  return api.post('/api/board/board/delete', formData);
}



//base64 인코딩이 아니면 enode
const switchBase64 = async (uploadFiles: any) => {
  const updatedUploadFiles = [];
  
  for (const url of uploadFiles) {
    if (url.startsWith('/api/file/')) {// 주어진 값이 '/api/file/'로 시작하는 경우
      const response = await fetch(url);// 해당 경로에 있는 파일을 가져와서 Base64로 인코딩
      const blob = await response.blob();
      const reader = new FileReader();
      
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64Data = reader.result;
        updatedUploadFiles.push(base64Data);
      };
    } else {
      if(!url.startsWith('data:image')) {
        alert("!url.startsWith('data:image')")
      }else {
        updatedUploadFiles.push(url);
      }
    }
  }

  return updatedUploadFiles;
};




//전송전 src 지우기
const removeSrcOld = (content: string | undefined): string | undefined => {
  if (content === undefined || content === '') return undefined;

  const parser = new DOMParser();

  const doc = parser.parseFromString(content, 'text/html');

  const imgElements = doc.querySelectorAll('img');
  imgElements.forEach(img => img.setAttribute('src', ''));

  return doc.body.innerHTML;
}


//전송전 src 지우기, name으로 순번 추가
const removeSrc = (content: string | undefined): string | undefined => {
  if (content === undefined || content === '') return undefined;

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  //name으로 순번매기기
  const imgElements = doc.querySelectorAll('img');
  imgElements.forEach((img, index) => {
    img.setAttribute('src', '');
    img.setAttribute('name', `${index + 1}`);
  });

  return doc.body.innerHTML;
};



const BoardAPI = { boardList, save, detail, update, deleteBoard };

export default BoardAPI;
