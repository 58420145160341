import Dummy from "../../Dummy";
import VisualSlide from "../../VisualSlide";
import FadeSlider2 from "../../FadeSlider2";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/RootReducer";
import { useEffect, useState } from "react";
import useAuthorization from "../../../hooks/useAuthorization";
import { AskItem, Page, Role, initAskParams, ASKSTATUS, ASKCATEGORY } from "../../../types/types";
import AskAPI from "../../../apis/AskAPI";
import NoContent from "../../NoContent";
import Pagination from "../../Pagenation";

const AskList = () => {

  const slides = [VisualSlide];
  const navigate = useNavigate();
  const userState = useSelector((state: RootState) => state.user);
  const SLIDE_TEXT = "Ask";
  const wrapper = document.getElementById('wrapper');


  const [handleAuth, setHandleAuth] = useState(useAuthorization());


  //list search
  const [page, setPage] = useState(1);
  const [searchType, setSearchType] = useState('all');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [askList, setAskList] = useState<Page<AskItem> | null>(null);
  const [params, setParams] = useState(initAskParams);

  //searcKeyword
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => { load() }, []);

  useEffect(() => {
    setHandleAuth(userState.user.roles === Role.ADMIN)
  }, [userState]);


  useEffect(() => {
    setParams(prevParams => ({ ...prevParams, page: page, searchType: searchType, searchKeyword: searchKeyword }));
  }, [page, searchType, searchKeyword]);

  //enter 검색
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      // load();
      // window?.scrollTo({ top: 0, behavior: 'smooth' });//화면상단 자동스크롤
    }
  };


  //Page리스트 변경
  const pageChange = (page: number) => {
    const viewportHeight = window.innerHeight;
    wrapper?.scrollTo({ top: viewportHeight, behavior: 'smooth' });
    setPage(page);
    load(page);
  }


  const itemClick = (item: AskItem, event?: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    window?.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/ask/' + item.id)
  };


  //목록조회 API
  const load = (pageNumber?: number) => {
    const updatedParams = {
      ...params,
      page: pageNumber !== undefined ? pageNumber : params.page
    };

    console.log('sendParams: ', updatedParams);

    AskAPI.list(updatedParams)
      .then((res) => {
        console.log('response: ', res);
        setAskList(res.data);
      })
      .catch((error) => {
        console.log('AskAPI-E: ', error.response?.data?.message);
        setAskList(null);
      });
  };



  //등록하기
  const goSaveForm = () => {
    if (userState.isLogin) {
      navigate('/askSave')
    } else {
      // TODO: 로그인 성공 후 다시 돌아와야함
      if (window.confirm('로그인 후 작성가능합니다. 로그인 페이지로 이동할까요?')) {
        navigate('/login', { replace: true })
      };
    }
  }

  return (
    <div>

      <FadeSlider2 components={slides} text={SLIDE_TEXT} />

      <div className="container mt-8">
        <div className="d-flex justify-content-center align-items-center my-5">
          <span className="col-4 border-bottom mx-2"></span>
          <h1 className="col-4 text-center my-0">문의하기</h1>
          <span className="col-4 border-bottom mx-2"></span>
        </div>
      </div>

      <div className="container mt-5" style={{ minHeight: '100vh' }}>
        <div className="d-flex justify-content-between mt-2">
          <button type="button" className="btn btn-primary mb-5" onClick={goSaveForm}>등록하기</button>
          {/* <button type="button" className="btn btn-primary mb-5" onClick={submitPost}>게시글 등록</button> */}
        </div>
        <div className="" style={{ overflowX: 'auto' }}>
          <table className="table table-striped table-hover align-middle" style={{
                border: 'solid 1px',
                borderColor: 'gainsboro'
          }}>
            {/* status, category, name, createdAt*/}
            <colgroup>
              <col style={{ minWidth: '70px' }} />{/* 상태 */}
              <col style={{ minWidth: '100px' }} />{/* 종류 */}
              <col style={{ minWidth: '100px' }} />{/* 작성자 */}
              <col style={{ minWidth: '150px' }} />{/* 작성일 */}
            </colgroup>
            <thead style={{ backgroundColor: 'rgb(239 239 239)', color: '#343a40', borderBottom: '1px solid #dee2e6', textAlign: 'center' }}>
              <tr>
                <th scope="col" style={{ fontFamily: 'Roboto' }}>상태</th>
                <th scope="col" style={{ fontFamily: 'Roboto' }}>종류</th>
                <th scope="col" style={{ fontFamily: 'Roboto' }}>작성자</th>
                <th scope="col" style={{ fontFamily: 'Roboto' }}>작성일</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: 'center' }}>
              {askList && askList.content.length > 0 ?
                <>
                  {askList.content.map((item, index) => (
                    <tr key={index} onClick={(event) => itemClick(item, event)}>
                      <th scope="row">{ASKSTATUS[item.status]}</th>
                      <td>{ASKCATEGORY[item.category]}</td>
                      <td>{item.name}</td>
                      <td>{item.createdAt}</td>
                    </tr>
                  ))}
                </>
                : null}
            </tbody>
          </table>
          <Pagination
            page={page}
            totalPageCount={askList ? askList.totalPages : 0}
            onPageChange={pageChange} />
        </div>
      </div>


    </div>


  );


}

export default AskList;





