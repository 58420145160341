import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers/RootReducer';
import { useNavigate } from 'react-router-dom';
import { DEV_MODE, Role } from '../types/types';


const useAuthorization = () => {

  const userState = useSelector((state: RootState) => state.user);

  const handleAuth = () => {

    if(DEV_MODE) return true;

    if(userState.user.roles === Role.ADMIN) {
      return true;
    } else {
      return false;
    }
  }

  return handleAuth();
}

export default useAuthorization;
