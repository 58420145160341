import { useEffect, useState } from "react";
import visual1 from '../assets2/download/jack-swords-Wv8HKvDZPQE-unsplash.jpg';


const CommunitySlide1: React.FC<{ active: boolean }> = ({active}) => {
  const [showFirTxt, setShowFirTxt] = useState(false);

  useEffect(() => {
    if(active){
      setTimeout(() => {
        setShowFirTxt(true);
      }, 500); 

      setTimeout(() => {
        // setShowFirTxt(true);
      }, 4000);
    }
  }, [active])

  return (
    <div className="slide-inner">
      <img className="video_responsive" src={visual1}/>
      <div className="visual-wrap appeared-warp">
        <h1 className={`visual-txt ${showFirTxt? "appeared-active" : "appeared-inactive"}`} >Community</h1>
      </div>
    </div>
  );
};

export default CommunitySlide1;