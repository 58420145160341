import Next2Icon from "../assets/ic_next2.png";
import Next1Icon from "../assets/ic_next.png";
import Prev2Icon from "../assets/ic_prev2.png";
import Prev1Icon from "../assets/ic_prev.png";

/**
 * 테이블 하단 페이징
 * @param page 현재 페이지
 * @param totalPageCount 총 페이지 수
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
const Pagination = ({
	page,
	totalPageCount = 1,
	onPageChange,
}: {
	page: number; 
	totalPageCount?: number;
	onPageChange: (page: number) => void;
}) => {
	const blockSize = 5;
	const currentBlock = Math.ceil(page / blockSize);
	const totalBlockSize = Math.ceil(totalPageCount / blockSize);
	const pageStart = (currentBlock - 1) * blockSize + 1;
	const pages = Array.from(
		{ length: blockSize },
		(_, i) => i + pageStart
	).filter((page) => page <= totalPageCount);

	const firstButtonShowing = currentBlock > 1;
	const lastButtonShowing = currentBlock < totalBlockSize;
	const prevButtonShowing = page > 1;
	const nextButtonShowing = page < totalPageCount;

	const handleFirstPage = () => {
		if (firstButtonShowing) {
			onPageChange(1);
		}
	};

	const handleLastPage = () => {
		if (lastButtonShowing) {
			onPageChange(totalPageCount);
		}
	};

	const handlePrevPage = () => {
		if (prevButtonShowing) {
			onPageChange(page - 1);
		}
	};

	const handleNextPage = () => {
		if (nextButtonShowing) {
			onPageChange(page + 1);
		}
	};

	return (
		<div id="paging1" className="paging1 mt40 mb40">
			<p>
				{firstButtonShowing && (
					<span className="numPN m_ar">
						<a
							
							data-page="1"
							title="처음 페이지로 이동하기"
							onClick={handleFirstPage}
						>
							<img src={Prev2Icon} alt="처음 페이지" />
						</a>
					</span>
				)}
				{prevButtonShowing && (
					<span className="numPN over left">
						<a
							
							data-page="1"
							title="이전 페이지로 이동하기"
							onClick={handlePrevPage}
						>
							<img src={Prev1Icon} alt="이전 페이지" />
						</a>
					</span>
				)}
				{pages.map((p) => (
					<span key={p} className={p === page ? "Present" : ""}>
						<a
							
							className="on"
							data-page="1"
							onClick={() => onPageChange(p)}
						>
							{p}
						</a>
					</span>
				))}
				{nextButtonShowing && (
					<span className="numPN  over right">
						<a
							
							data-page="11"
							title="다음 페이지로 이동하기"
							onClick={handleNextPage}
						>
							<img src={Next1Icon} alt="다음 페이지" />
						</a>
					</span>
				)}
				{lastButtonShowing && (
					<span className="numPN m_ar">
						<a
							
							data-page="14"
							title="마지막 페이지로 이동하기"
							onClick={handleLastPage}
						>
							<img src={Next2Icon} alt="마지막 페이지" />
						</a>
					</span>
				)}
			</p>
		</div>

	);
};

export default Pagination;
