import { useEffect, useState } from "react";
// import visual1 from '../assets/bf1/1.jpg';
import visual1s from '../assets/ppt/03-1.jpg';
import visual1 from '../assets/format/KakaoTalk_20231021_020444303.jpg';


const Slide1: React.FC<{ active: boolean }> = ({active}) => {
  const [showFirTxt, setShowFirTxt] = useState(false);

  useEffect(() => {
    if(active){
      setTimeout(() => {
        setShowFirTxt(true);
      }, 500);

      setTimeout(() => {
        setShowFirTxt(false);
      }, 4000);
    }
  }, [active])

  return (
    <div className="slide-inner">
      <img className="video_responsive" src={visual1}/>
      {/* <img className="video_responsive" src='https://source.unsplash.com/random'/> */}
      
      <div className="txt-wrap">
        <div className="txt-ani-fir">
          <p className={showFirTxt ? "txt-act-fir" : "txt-inact-fir"}>
            <span className="txt-con-fir">건강한 라이프스타일을 향한 첫걸음</span>
          </p>
        </div>
        <div className="txt-ani-fir">
          <p className={showFirTxt ? "txt-act-fir" : "txt-inact-fir"}>
            <span className="txt-con-fir">당신의 미래를 위한 아름다움의 시작,</span>
          </p>
        </div>
        <div className="txt-ani-sec">
          <p className={showFirTxt ? "txt-act-sec" : "txt-inact-sec"}>
            <span className="txt-con-sec">YoonSkin-Wellness</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Slide1;

{/* <video
        className="video_responsive"
        autoPlay
        muted
        loop
        src="https://www.claviscorp.com/video/visualVideo.mp4"
      /> */}
