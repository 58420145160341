import { combineReducers } from "redux";
import userSlice from "./userSlice";
import joinSlice from "./joinSlice";
import exceptionSlice from "./exceptionSlice";

const rootReducer = combineReducers({
  user: userSlice,
  exception: exceptionSlice,
  join: joinSlice,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;