import React from "react";
import { useEffect, useState } from "react";
import FadeSlider from "./FadeSlider";
import CardSection from "./CardSection";
import SwipeSection from "./SwipeSection";
import PPT1 from "./PPT1";
import PPT3 from "./PPT3";
import PPT2 from "./PPT2";
import Slide1 from "./Slide1";
import Slide2 from "./Slide2";

const Main = (): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  const slides = [Slide1, Slide2];

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <main className={`ffade-slider ${isVisible ? "vvisible" : ""}`}>
      <FadeSlider components={slides} />
      <CardSection />
      <PPT1 />
      <PPT3 />
      <PPT2 />
      {/* <TabSlider2 /> */}
      <SwipeSection />
      {/* <Dummy /> */}
    </main>
  );
}

export default Main;

/** <h1 className="notext">전체 영역</h1>
      <nav id="lnb">
        <h2 className="notext">네비게이션 영역</h2>
        <div className="lnb_menu">
          <ul className="lnb_dep1">
            <li className="lnb_dep1_li">
              <a href="#" className="home">
                <img src="../assets/ic_home.png" alt="메인으로" />
              </a>
            </li>
            <li className="lnb_dep1_li">
              <a href="#">소통마당</a>
              <ul className="lnb_dep2">
                <li className="active">
                  <a href="#">소통마당</a>
                </li>
                <li>
                  <a href="#">참여마당</a>
                </li>
                <li>
                  <a href="#">정보마당</a>
                </li>
              </ul>
            </li>
            <li className="lnb_dep1_li end on">
              <a href="#">참여소개</a>
              <ul className="lnb_dep2">
                <li className="active">
                  <a href="#">참여소개</a>
                </li>
                <li>
                  <a href="#">참여내용</a>
                </li>
                <li>
                  <a href="#">참여공지</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="lnb_txt">
          <h3>소통마당</h3>
          <select>
            <option>문산마을</option>
            <option>첨단마을</option>
            <option>사직마을</option>
          </select>
          <p>문흥1동, 문흥2동, 오치1동</p>
        </div>
      </nav> */