import { useEffect, useRef, useState } from "react";
import { ChildMenuProps, MenuItem, UserState, YOONSKINMENU } from "../types/types";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers/RootReducer";
import { useDispatch } from "react-redux";
import logo from '../assets/header_logo.png';
import fakelogo from '../assets/fake-logo2.png';
import login01 from '../assets/login01.png';
import login02 from '../assets/login02.png';
import login03 from '../assets/login03.png';
import login04 from '../assets/login04.png';
import UserAPI from "../apis/UserAPI";
import { logout } from "../redux/actions/userAsyncsActions";

interface HaderProps {
  hamClick: () => void;
  userState: UserState;
}

const Header = (param: HaderProps): JSX.Element => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDownScrollY, setIsDownScrollY] = useState(false);
  const prevScrollYRef = useRef(0);
  const wrapper = document.getElementById('wrapper');

  useEffect(() => {
    document.getElementById('wrapper')?.addEventListener('scroll', handleScroll);
    return () => {
      document.getElementById('wrapper')?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const windowScrollY = document.getElementById('wrapper')?.scrollTop;
    if (windowScrollY && windowScrollY > 0) {//isScrolled
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }

    if (windowScrollY && windowScrollY > prevScrollYRef.current) {//idDownScrolled
      setIsDownScrollY(true);
    } else {
      setIsDownScrollY(false);
    }

    if (windowScrollY === 0) {
      setIsDownScrollY(false)
    }
    prevScrollYRef.current = windowScrollY ? windowScrollY : 0;

  };

  //이동함수
  const handleHeader = (e: string) => {
    wrapper?.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/' + e)
  }

  // 로그아웃
  const logoutHandle = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      dispatch(logout() as any);
      navigate('/')
    }
  };

  //로고 clickEvent
  const logoClick = () => {
    window.location.href = '/'
    wrapper?.scrollTo({ top: 0, behavior: 'smooth' });
  }


  //소메뉴
  const ChildMenu = (props: ChildMenuProps) => {

    const handleChildClick = (e: React.MouseEvent<HTMLLIElement>) => {
      e.stopPropagation(); // 이벤트 전파 중지
      handleHeader(e.currentTarget.dataset.link || '');
    };
    return (
      <>
        {props.menuDeps.MENU_ITEM.map((menuItem, index) => (
          <li key={'chi-li' + index} onClick={handleChildClick} data-link={menuItem.LINK}>
            <a>{menuItem.NAME}</a>
          </li>
        ))}
      </>
    );
  };

  return (
    <header className={`${isScrolled ? '' : 'fixed'} ${isDownScrollY ? 'down' : ''}`}>
      <h1 className="notext">헤더 영역</h1>
      <div id="header">

        <div className="header_login">
          {param.userState.dataLoad && param.userState.isLogin ?
            <ul className="login_member">
              <li>
                <a onClick={logoutHandle}>
                  <img src={login03} alt="로그아웃" />
                  로그아웃
                </a>
              </li>
              <li>
                <a onClick={() => navigate('/myPage')}>
                  <img src={login04} alt="마이페이지" />
                  마이페이지
                </a>
              </li>
            </ul>
            :
            <ul className="login_guest">
              <li>
                <a onClick={() => { wrapper?.scrollTo({ top: 0, behavior: 'smooth' }); navigate('/login'); }}>
                  <img src={login01} alt="로그인" />
                  로그인
                </a>
              </li>
              <li>
                <a onClick={() => { wrapper?.scrollTo({ top: 0, behavior: 'smooth' }); navigate('/join') }}>
                  <img src={login02} alt="회원가입" />
                  회원가입
                </a>
              </li>
            </ul>
          }
        </div>

        <div className="header_menu">
          <a onClick={logoClick} className="logo">
            <img className="header-logo" src={fakelogo} alt="윤스킨 웰니스 홈페이지 로고" />
          </a>
          <nav id="gnb">
            <ul className="gnb_dep1">
              {Object.keys(YOONSKINMENU).map((key, index) => (
                <li className="gnb_dep1_li" key={key + index}
                  onClick={() => handleHeader(YOONSKINMENU[key as keyof typeof YOONSKINMENU].LINK)}
                >
                  <a className="gnb_dep1_a" >{key}</a>
                  {YOONSKINMENU[key as keyof typeof YOONSKINMENU].MENU_ITEM.length > 0 && (
                    <ul className="gnb_dep2">
                      <ChildMenu
                        title={key}
                        menuDeps={YOONSKINMENU[key as keyof typeof YOONSKINMENU]}
                      />
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <button type="button" className="mobile_ham" onClick={param.hamClick}>
            <span>모바일메뉴열기</span>
          </button>
        </div>

      </div>
    </header>
  );
};

export default Header;
