import React, { useEffect, useState } from 'react';
import FadeSlider2 from "../FadeSlider2";
import VisualSlide from "../VisualSlide";
import ReelsAPI from '../../apis/ReelsAPI';
import { ReelsParams, InstagramResponse } from '../../types/types';
import NoContent from '../NoContent';
import throttle from 'lodash/throttle';



const Reels = () => {

  const slides = [VisualSlide];

  //list search
  const SLIDE_TEXT = "Reels";
  const [requestReels, setRequestReels] = useState(false);//api 중복호출 방지
  const [reelsResponse, setReelsResponse] = useState<InstagramResponse | null>(null);
  const [params, setParams] = useState<ReelsParams>(() => ({
    next: undefined,
    previous: undefined,
    limit: 12,
  }));


  useEffect(() => {
    load()
  }, []);

  const handleAppend = () => {
    load();
  }

  // //requestReels
  // useEffect(() => {
  //   if (requestReels) {
  //     appendData()
  //   }
  // }, [requestReels]);


  //async list 호출
  // const appendData = async () => {
  //   try {
  //     await load();
  //     setRequestReels(false)
  //   } catch (error) {
  //     console.log('Error:', error);
  //   }
  // };

  //list 조회 함수
  const load = (pageNumber?: number) => {
    console.log('API호출');
    ReelsAPI.reelsList(params)
      .then((res) => {
        console.log('API결과: ', res);

        if (reelsResponse?.data && reelsResponse?.data.length > 0) {
          appendList(res.data);
        } else {
          setReelsResponse(res.data);
        }

        const resNext = res.data.paging.next;

        setParams(prevParams => ({
          ...prevParams,
          next: resNext ? resNext : undefined,
          limit: params.limit
        }));
      })
      .catch((error) => {
        console.log('ReelsAPI-E: ', error.response?.data?.message);
        setReelsResponse(null);
      });

    return Promise.resolve();
  };

  const appendList = (response: InstagramResponse) => {
    setReelsResponse((prevRes: InstagramResponse | null) => ({
      ...prevRes,
      data: [...(prevRes?.data || []), ...response.data],
      paging: prevRes?.paging || { cursors: { before: undefined, after: undefined }, next: '', previous: '' },
    }));
  };


  return (
    <div>
      <FadeSlider2 components={slides} text={SLIDE_TEXT} />
      <div>

        {/* {reelsResponse && reelsResponse.data && reelsResponse.data.length > 0 ?
          <>
            <div className="commu-list reels-list-container">
              {reelsResponse.data.map((item, index) => (
                <div key={index} className="item">
                  <a href={item.permalink} className="item-card" target="_blank" rel="noopener noreferrer">
                    {item.thumbnail_url !== null && item.thumbnail_url !== '' ?
                      <div
                        className="reels-item-image"
                        style={{ backgroundImage: `url(${item.thumbnail_url})` }} />
                      :
                      <div
                        className="reels-item-image"
                        style={{ backgroundImage: `url(${item.media_url})` }} />
                    }
                    <article>
                      <h1 className='item-text'>{item.caption}</h1>
                      
                    </article>
                  </a>
                </div>
              ))}
            </div>
          </>
          : <NoContent />} */}

        {reelsResponse && reelsResponse.data && reelsResponse.data.length > 0 ?
          <>
            <div className="commu-list reels-list-container">
              {reelsResponse.data.map((item, index) => {
                const firstLine = item.caption?.split('\n')[0];

                return (
                  <div key={index} className="item">
                    <a href={item.permalink} className="item-card" target="_blank" rel="noopener noreferrer">
                      {item.thumbnail_url !== null && item.thumbnail_url !== '' ?
                        <div
                          className="reels-item-image"
                          style={{ backgroundImage: `url(${item.thumbnail_url})` }} />
                        :
                        <div
                          className="reels-item-image"
                          style={{ backgroundImage: `url(${item.media_url})` }} />
                      }
                      <article className='reels-article'>
                        <h1 className='item-text'>{firstLine}</h1>
                      </article>
                    </a>
                  </div>
                );
              })}
            </div>
          </>
          : <NoContent />}


        {params.next?
          <div className='more-btn-wrap'>
            <button className="btn btn-primary more-btn" onClick={handleAppend}>더보기</button>
          </div>
          :
          <></>
        }
      </div>
    </div>
  );
}


export default Reels;