import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthorization from '../../../hooks/useAuthorization';
import VisualSlide from "../../VisualSlide";
import FadeSlider2 from '../../FadeSlider2';
import visual1 from '../../../assets2/test04.png';

import AskAPI from '../../../apis/AskAPI';
import { AskItem, AskSaveForm, Role, initCommunityParams } from '../../../types/types';
import { RootState } from '../../../redux/reducers/RootReducer';
import { useSelector } from 'react-redux';

/**
 * api response
{"id":30,"title":"첫번쨰 게시글1","link":null,"content":"<p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p><p>첫쨰줄 내용입니다.</p><p>두번쨰줄 블라블라</p><p><img src=\"/api/file/getFile?savedPath=/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png\"></p><p>이것은 팬더</p><p>뚱뚱하다</p>","enabled":1,"createdId":16,"createdAt":"2024-01-25","updatedId":null,"updatedAt":null,"deletedId":null,"deletedAt":null,"boardFileList":[{"id":16,"fileRealName":"file1.png","fileStoreName":"e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png","filePath":"/board/2024/1/e29d830a-1fdf-48d3-b5a7-a4bc967ac0cd.png","createdAt":"2024-01-25","boardId":30}]}
 */


const AskDetail = () => {
  const SLIDE_TEXT = "Ask";
  const slides = [VisualSlide];
  const { id } = useParams();
  const [askItem, setAskItem] = useState<AskItem | null>(null);
  const [params, setParams] = useState(initCommunityParams);
  const userState = useSelector((state: RootState) => state.user);
  const [handleAuth, setHandleAuth] = useState(false);
  const navigate = useNavigate();

  const [firstComment, setFirstComment] = useState('');
  const [editOpen, setEditOpen] = useState(false);

  //랜더링 시 API 호출
  useEffect(() => {
    const param = {
      ...params,
      id: (id !== undefined) ? id : undefined,
    };

    AskAPI.detail(param)
      .then((res) => {
        setAskItem(res.data);
        setFirstComment(res.data.comment || '')
        setEditOpen(res.data?.status === 3)
        console.log(res.data)
      })
      .catch((error) => {
        console.log('askAPI-E: ', error.response?.data?.message);
        setAskItem(null);
        alert('잠시 후에 다시 시도해주세요.')
        // navigate('/COMMUNITY')
      });

  }, []);


  const handleCommentChange = (e: any) => {
    setAskItem(prevAskItem => prevAskItem ? { ...prevAskItem, comment: e.target.value } : null);
  };

  useEffect(() => {
    setHandleAuth(userState?.user.roles === Role.ADMIN ? true : false)
  }, [userState]);

  const onSubmit = (data: any) => {
    if (askItem?.status === 1) {
      if (askItem?.comment === null || askItem?.comment === '') {
        alert('내용을 작성해주세요.')
        return;
      }
    } else if (askItem?.status === 3) {
      if (editOpen) {
        if (window.confirm('답글을 수정하시겠습니까?')) {
          setEditOpen(false)
          return;
        } else {
          return;
        }
      }
      if (askItem?.comment === firstComment) {
        alert('내용을 수정해주세요.')
        return;
      }
    }

    if (userState.isLogin) {
      const ask = data as AskSaveForm;
      if (id != undefined) {
        ask.id = parseInt(id)
      }
      ask.comment = askItem?.comment
      console.log(ask)
      AskAPI.comment(ask).then((res) => {
        alert('등록되었습니다.')
        // navigate('/ask/' + id, { replace: true })
        window.location.reload();
      }).catch((e) => {
        alert("error 발생: " + e)
      });
    } else {
      // TODO: 로그인 성공 후 다시 돌아와야함
      if (window.confirm('로그인 후 작성가능합니다. 로그인 페이지로 이동할까요?')) {
        navigate('/login', { replace: true })
      };
    }

  };

  const moveEditForm = () => {
    if (handleAuth)
      navigate('/ask/' + id + '/edit', { replace: false })
  }

  const movePriv = () => {
    navigate(-1)
  }

  // const deleteBoard = () => {
  //   if(window.confirm('삭제하시겠습니까?')){
  //     if (handleAuth){
  //       const param = {
  //         ...params,
  //         id: (id !== undefined) ? id : undefined,
  //       };
  //       AskAPI.delete(param)
  //         .then((res) => {
  //             alert('삭제되었습니다.')
  //             navigate('/COMMUNITY', { replace: false })
  //         })
  //         .catch((error) => {
  //           alert('삭제를 실패하였습니다. 잠시 후 다시 시도해주세요.')
  //         });
  //     } else {
  //       alert('비정상 적인 접근')
  //       navigate('/', { replace: false })
  //     }
  //   }
  // }

  return (
    <div>

      <FadeSlider2 components={slides} text={SLIDE_TEXT} />

      {/* <div className="d-flex justify-content-between mt-2"> */}
        {/* <button type="button" className="btn btn-secondary mb-5" onClick={prevBtn}>뒤로가기</button>
        <button type="button" className="btn btn-primary mb-5" onClick={submitPost}>게시글 등록</button> */}

      {/* </div> */}

      <div className="container mt-8">
        <div className="d-flex justify-content-center align-items-center my-5">
          <span className="col-4 border-bottom mx-2"></span>
          <h1 className="col-4 text-center my-0">Contact Us</h1>
          <span className="col-4 border-bottom mx-2"></span>
        </div>
      </div>

      <div className="container my-5">
        <form >
          <div className="row">
            <div className="col-md-6">
              <img src={visual1} alt="Contact Image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <div className="col-md-6 form-container" style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="form-group mb-3">
                <label>Category</label>
                <select className={`form-select `} value={askItem?.category} disabled>
                  <option value="" defaultValue={''}>전체</option>
                  <option value="1">상품문의</option>
                  <option value="2">레슨문의</option>
                  <option value="3">기타문의</option>
                </select>
                <div className="valid-feedback">Ok!</div>
              </div>
              <div className="form-group mb-2">
                <label>Name</label>
                <input type="text" className={`form-control`}
                  placeholder="윤스킨" value={askItem?.name} disabled />
                <div className="valid-feedback">Ok!</div>
                <div className="invalid-feedback">이름을 입력하세요.</div>
              </div>
              <div className="form-group mb-2">
                <label>Email</label>
                <input type="email" className={`form-control `}
                  placeholder="yoonSKin@yoonSkin.com" value={askItem?.email} disabled />
                <div className="valid-feedback">Ok!</div>
                <div className="invalid-feedback">유효한 이메일을 입력하세요.</div>
              </div>
              <div className="form-group mb-2">
                <label>Phone</label>
                <input type="tel" className={`form-control `}
                  placeholder="01012345678" value={askItem?.telno} disabled />
                <div className="valid-feedback">Ok!</div>
                <div className="invalid-feedback">유효한 전화번호를 입력하세요.</div>
              </div>
              <div className="form-group mb-2">
                <label>Message</label>
                <textarea className={`form-control `}
                  placeholder="궁금하신 내용을 적어주세요!" value={askItem?.content} disabled></textarea>
                <div className="valid-feedback">Ok!</div>
                <div className="invalid-feedback">메시지를 입력하세요.</div>
              </div>
            </div>
          </div>


          {/* admin */}
          {(handleAuth && userState.user.roles === Role.ADMIN) &&
            <div className="mt-3 col-md-12 form-container" style={{ minHeight: '30vh', display: 'flex', flexDirection: 'column' }}>
              <div className="form-group mb-2" style={{ flex: 1, display: 'flex' }}>
                <textarea className={`form-control `}
                  style={{ flex: 1, resize: 'none' }} 
                  placeholder='내용을 입력해주세요.'
                  value={askItem?.comment || ''}
                  onChange={handleCommentChange}
                  disabled={editOpen}
                >
                </textarea>
              </div>

              <div className="d-flex justify-content-center mt-4 gap-3">
                <button type="button" onClick={() => { navigate('/askList') }} className="btn btn-secondary">목록으로</button>
                {(askItem?.status === 1) ?
                  <button type="button" onClick={onSubmit} className="btn btn-primary">답변하기</button>
                  :
                  <button type="button" onClick={onSubmit} className="btn btn-primary">수정하기</button>
                }
              </div>
            </div>
          }


          {/* !admin */}
          {(userState.user.roles !== Role.ADMIN && askItem?.status === 3 && askItem?.comment !== '') &&
            <div className="mt-3 col-md-12 form-container" style={{ minHeight: '30vh', display: 'flex', flexDirection: 'column' }}>
            <div className="form-group mb-2" style={{ flex: 1, display: 'flex' }}>
              <textarea className="form-control" 
                style={{ flex: 1, resize: 'none' }} 
                value={askItem?.comment || ''} 
                disabled={true}>
              </textarea>
            </div>
          
            <div className="d-flex justify-content-center mt-4">
              <button type="button" onClick={() => { navigate('/askList') }} className="btn btn-secondary">목록으로</button>
            </div>
          </div>
          
          }

        </form>
      </div>


    </div>


  );
};

export default AskDetail;