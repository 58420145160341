import React, { useEffect, useState } from 'react';

const WindowSizeDisplay: React.FC = () => {
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{position: 'fixed', zIndex: 10000}}>
      <p>{windowSize.width} x {windowSize.height}</p>
    </div>
  );
};

export default WindowSizeDisplay;
