import React, { PropsWithChildren, useContext, useState } from "react";
import TimeUtil from "../utils/TimeUtil";

/**
 *  전역에서 팝업을 관리하기 위한 컴포넌트
 */
const DialogProvider = ({ children }: PropsWithChildren) => {

  const [dialogs, setDialogs] = useState([] as Dialog[]);

  const showDialog: ShowDialogFn = async (type, props) => {
    
    const dialog: Dialog = {
      id: dialogs.length,
      type: type as React.ComponentType<DialogProps>,
      props: props,
      isOpen: false,
    }
    const newArr = [...dialogs, dialog];
    //setDialogs(newArr);

    await TimeUtil.delay(0);
    const openedDialog: Dialog = {
      ...dialog,
      isOpen: true,
    };
    setDialogs([...newArr.slice(0, -1), openedDialog]);
  };

  const handleClose = async (id: number, all?: any) => {
    const dialog = dialogs.find((dialog) => dialog.id === id);
    if(!dialog){
      return;
    }
    const closedDialog: Dialog = {
      ...dialog,
      isOpen: false,
    };
    const newArr = dialogs.filter((dialog) => dialog.id !== id);
    setDialogs([...newArr, closedDialog]);

    await TimeUtil.delay(200);
    setDialogs(newArr);
    if(all){
      setDialogs([])
    }
  };

  return(
    <DialogContext.Provider value={{ showDialog }}>
      {dialogs.map((dialog) => (
        <dialog.type 
          key={dialog.id}
          onClose={(e: any) => {
            if(e) {
              handleClose(dialog.id, e)
            }else {
              handleClose(dialog.id)
            }
          }}
          style={{
            display: 'flex',
            transition: 'all .2s',
            opacity: dialog.isOpen ? 1 : 0,
          }}
          {...dialog.props}
        />
      ))}
      {children}
    </DialogContext.Provider>
  );
};

export interface DialogProps {
  style?: React.CSSProperties;
  onClose?: (e?:any) => void;
}

interface Dialog<P extends DialogProps = DialogProps> {
  id: number;
  type: React.ComponentType<P>;
  props?: P;
  isOpen: boolean;
}

type ShowDialogFn = <P extends DialogProps>(
  type: React.ComponentType<P>,
  props?: P
) => any;

const DialogContext = React.createContext<{
  showDialog: ShowDialogFn;
}>({ showDialog: () => {}});

export const useDialog = () => useContext(DialogContext);

export default DialogProvider;


