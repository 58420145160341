import Dummy from "../Dummy";

//import '../styles/base.css'
const Preference = () => {
  return (
    <div>
      <h1>Preference</h1>
      <p>Preference Page</p>
      <Dummy/>
    </div>
  )
}

export default Preference;