import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from '../redux/reducers/RootReducer';
import { useState } from 'react';
import { DEV_MODE } from '../types/types';


interface ProtectedRouteProps {
  children: React.ReactNode;
}

/**
 * Page 접속 보호처리
 * @param param0 보호된 component
 * @returns 보호된 component | 로그인 component
 */
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const currentLocation = useLocation();
  const userState = useSelector((state: RootState) => state.user);
  
  if(DEV_MODE) return <>{children}</>;

  if (userState.isLogin) {
    //TODO admin check
    return <>{children}</>;
  } else {
    if(window.confirm('로그인이 필요합니다. 로그인하시겠습니까?')){
      return <Navigate to="/login" replace state={{redirectedFrom: currentLocation}} />;
    }else{
      return <Navigate to='/' replace />;
    }
  }
};

export default ProtectedRoute;
