import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import DialogProvider from './dialogs/DialogProvider';
import Store from './redux/Store';

import './styles/base.css';
import './styles/layout.css';
import './styles/common.css';
import './styles/commonWrite.css';
import './styles/commPopup.css';
import './styles/mypage.css';

import './styles/ppt2.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";



const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <Provider store={Store}>
      <DialogProvider>
        <App />
      </DialogProvider>
    </Provider>
  </BrowserRouter>

);

reportWebVitals();
