//import '../styles/base.css'
import '../../styles/login.css';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Authentication, UserState } from '../../types/types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, join2, kakaoLogin, login } from '../../redux/actions/userAsyncsActions';
import { RootState } from '../../redux/reducers/RootReducer';
// import kakaoLoginBtn from '../../assets/kakao_login_simple/ko/kakao_login_medium.png'
// import naverLoginBtn from '../../assets/2021_Login_with_naver_guidelines_Kr/2021_Login_with_naver_guidelines_Kr/btnG_축약형.png'
import kakaoLoginBtn from '../../assets/login_kakao.png'
import naverLoginBtn from '../../assets/login_naver.png'
import Dummy from '../Dummy';
import { useForm } from 'react-hook-form';
import pattern from '../../assets2/bg/pattern-00.png';
import visual1 from '../../assets2/download/jack-swords-Wv8HKvDZPQE-unsplash.jpg';

interface LoginProps {
  p_rightPanelActive?: boolean;
}

const Login: React.FC<LoginProps> = ({ p_rightPanelActive }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.redirectedFrom?.pathname || '/';//리다이렉트로 던졌으면 해당경로로 이동
  const userState = useSelector((state: RootState) => state.user);
  const wrapper = document.getElementById('wrapper');

  const [errMsg, setErrMsg] = useState('');
  const [rightPanelActive, setRightPanelActive] = useState(p_rightPanelActive);//false -> login , true -> join
  const [param, setParam] = useState<Authentication>({
    username_field: '',
    password_field: '',
  });


  // Login Form Validation
  const { register: loginRegister, handleSubmit: loginHandleSubmit, getValues: loginGetValues, formState: { errors: loginErrors }, clearErrors: loginClearErrors } = useForm({ mode: 'onChange' });

  // Join Form Validation
  const { register: joinRegister, handleSubmit: joinHandleSubmit, getValues: joinGetValues, formState: { errors: joinErrors }, clearErrors: joinClearErrors } = useForm({ mode: 'onChange' });
  const joinState = useSelector((state: RootState) => state.join);
  const JoinEmailError = joinState.validationResult?.errors.find(err => err.field === 'email');
  // const loginEmailError = loginState.validationResult?.errors.find(err => err.field === 'email');

  useEffect(() => {
    dispatch(fetchUser() as any)
  }, []);

  useEffect(() => {
    if(userState.isLogin){
      navigate('/myPage')
    }
  }, [userState]);

  useEffect(() => {
    joinClearErrors()
    loginClearErrors()
  }, [rightPanelActive]);

  useEffect(() => {
    if (location.pathname === '/login') {
      if (rightPanelActive) {
        setRightPanelActive(false)
      }
    } else if (location.pathname === '/join') {
      if (!rightPanelActive) {
        setRightPanelActive(true)
      }
    }
  }, [location]);

  // useEffect(() => {
  //   if (p_rightPanelActive) {
  //     setRightPanelActive(p_rightPanelActive)
  //   }
  // }, [])

  useEffect(() => {
    setErrMsg(userState.error)
  }, [userState.error])




  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setParam((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //로그인
  const signIn = async (data: any) => {
    const param = {
      username_field: data.email,
      password_field: data.password,
    };

    const response = await dispatch(login(param) as any);
    if (response.type === "user/login/fulfilled") {
      wrapper?.scrollTo({ top: 0, behavior: 'smooth' });

      navigate(from);
    }
  };

  //회원가입
  const onSubmit = async (data: any) => {
    const response = await dispatch(join2(data) as any);
    if (response.type === "user/join/fulfilled") {
      if (window.confirm('회원가입이 완료되었습니다. 로그인 화면으로 이동하시겠습니까?')) {
        window.location.href = '/login'
      } else {
        navigate('/');
      }
    } else {

    }
  };

  document.getElementById("form1")?.addEventListener("submit", (e) => e.preventDefault());
  document.getElementById("form2")?.addEventListener("submit", (e) => e.preventDefault());


  return (
    <div className="login-container-wrap">
      <div className="bg" style={{
        // backgroundImage: `url(${pattern}) no-repeat calc(50% - 43rem) 13rem, url(${pattern}) no-repeat calc(50% + 35rem) -32rem`,
        // backgroundColor: '#476ff3'
        // backgroundImage: `url(${visual1}) no-repeat calc(50% - 43rem) 13rem, url(${visual1}) no-repeat calc(50% + 35rem) -32rem`,
        // backgroundColor: '#476ff3'
      }} />
      <div style={{ zIndex: '2' }}>
        <h1 className="tit-text" style={{ fontSize: '3.5rem', letterSpacing: '-3px' }}>{rightPanelActive ? '회원가입' : '로그인'}</h1>
        <p className='p-text'>윤스킨 웰니스에 오신것을 환영합니다.</p>
      </div>
      <div className={`login-container ${rightPanelActive ? 'right-panel-active' : ''}`}>
        {/* Sign Up */}
        <div className="login-container__form login-container--signup">
          <form onSubmit={joinHandleSubmit(onSubmit)} className="form" id="form1">
            {/* <h2 className="mt-2 form__title">Sign Up</h2> */}
            <>
              <span className='span-field'>Email</span>
              <input
                {...joinRegister("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  validate: (value) => {
                    if (JoinEmailError && JoinEmailError.rejectedValue === value) {
                      return JoinEmailError.message;
                    }
                  }
                })}
                className={`input ${joinErrors.email ? 'red' : ''}`}
                type="text"
                placeholder='Email'
              />
              {joinErrors.email?.type === 'required' && <span className='red'>이메일을 입력해주세요.</span>}
              {joinErrors.email?.type === "pattern" && <span className='red'>이메일 형식을 확인해주세요.</span>}
              {JoinEmailError && JoinEmailError.rejectedValue === joinGetValues("email") && <span>{JoinEmailError.message}</span>}
            </>

            <>
              <span className='span-field'>Nickname</span>
              <input 
                {...joinRegister("nickname", {
                   required: true 
                  })} 
                  className={`input ${joinErrors.nickname ? 'red' : ''}`}
                type="text" 
                placeholder='Nickname'
              />
              {joinErrors.nickname?.type === 'required' && <span className='red'>닉네임을 입력해주세요.</span>}
            </>

            <>
              <span className='span-field'>Password</span>
              <input
                {...joinRegister("password", {
                  required: true,
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                })}
                className={`input ${joinErrors.password ? 'red' : ''}`}
                type="password"
                placeholder='Password'
              />
              {joinErrors.password?.type === "required" && <span className='red'>비밀번호를 입력해주세요.</span>}
              {joinErrors.password?.type === "pattern" && <span className='red'>비밀번호는 최소 8자 이상이며, 영문, 숫자, 특수문자(@$!%*#?&)를 포함해야 합니다.</span>}
            </>

            <>
              <span className='span-field'>Password Confirm</span>
              <input
                {...joinRegister("passwordConfirm", {
                  required: true,
                  validate: (value) => value === joinGetValues("password"),
                })}
                className={`input ${joinErrors.passwordConfirm ? 'red' : ''}`}
                type="password"
                placeholder='Password Confirm'
              />
              {joinErrors.passwordConfirm?.type === 'required' && <span className='red'>비밀번호를 입력해주세요.</span>}
              {joinErrors.passwordConfirm?.type === 'validate' && <span className='red'>비밀번호가  일치하지 않습니다.</span>}
            </>

            {/* <>
              <input className='input' type="text" placeholder='Name'
                {...register("username", { required: true })} />
              {joinErrors.username && <span>이름을 입력해주세요.</span>}
            </>

            <>
              <input className='input' type="tel" placeholder='Tel' {...register("telno", {
                required: true,
                pattern: /^\d{11}$/,
              })} />
              {joinErrors.telno?.type === "required" && <span>전화번호를 입력해주세요.</span>}
              {joinErrors.telno?.type === "pattern" && <span>전화번호는 11자 이상이어야 합니다.</span>}
            </> */}
            {/* <button className="l-btn mt-1">Sign Up</button> */}

            {/* 개인정보 동의 */}
            <div className="form-check mt-3">
              <input
                {...joinRegister("agreement", {
                  required: true,
                })}
                className={`form-check-input ${joinErrors.agreement ? 'red' : ''}`}
                type="checkbox"
                id="agreementCheckbox"
                style={{ marginTop: '3px' }} />
              <label
                className={`form-check-label ${joinErrors.agreement ? 'red' : ''}`}
                htmlFor="agreementCheckbox"
                style={{ display: 'block' }} >
                윤스킨웰니스
                <a role="button" className="btn-link" > 이용약관 </a>과<br className='responsive-br'/>
                <a role="button" className="btn-link" > 개인정보처리방침</a>
                에 <br className='responsive-br'/>동의합니다.
              </label>
            </div>


            <button type="submit" className="btn mt-2 py-3 btn-primary btn-lg btn-block submit-btn">회원가입</button>
            <button type='button' onClick={() => {window.location.href = '/login'}} className="btn mt-3 py-3 btn-light btn-lg btn-block submit-btn" style={{background: 'rgb(223 223 223)'}}>로그인</button>

            {/* social Login box */}
            <div className="d-flex justify-content-between mt-3 gap-2">
              <a href="http://localhost:9999/oauth2/authorization/kakao">
                <img className="social-login-box" src={kakaoLoginBtn} alt="카카오로그인버튼" />
              </a>
              <a href="http://localhost:9999/oauth2/authorization/naver">
                <img className="social-login-box" src={naverLoginBtn} alt="네이버로그인버튼" />
              </a>
            </div>
          </form>
        </div>

        {/* Sign In */}
        <div className="login-container__form login-container--signin">
          <form onSubmit={loginHandleSubmit(signIn)} className="form" id="form2">
            {/* <input type="email" placeholder="Email" className="input" />
            <input type="password" placeholder="Password" className="input" /> */}

            <>
              <span className='span-field'>Email</span>
              <input
                {...loginRegister("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
                className={`input ${loginErrors.email ? 'red' : ''}`}
                type="email"
                placeholder='Email'
              // id="username_field"
              // name="username_field"
              // value={param.username_field}
              // onChange={handleChange}
              />
              {loginErrors.email?.type === 'required' && <span className='red'>이메일을 입력해주세요.</span>}
              {loginErrors.email?.type === "pattern" && <span className='red'>이메일 형식을 확인해주세요.</span>}
            </>
            <>
              <span className='span-field'>Password</span>
              <input
                {...loginRegister("password", {
                  required: true,
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                })}
                className={`input ${loginErrors.password ? 'red' : ''}`}
                type="password"
                placeholder='Password'
              // id="password_field"
              // name="password_field"
              // value={param.password_field}
              // onChange={handleChange}
              />
              {loginErrors.password?.type === "required" && <span className='red'>비밀번호를 입력해주세요.</span>}
              {loginErrors.password?.type === "pattern" && <span className='red'>비밀번호는 최소 8자 이상이며, 영문, 숫자, 특수문자(@$!%*#?&)를 포함해야 합니다.</span>}
            </>

            <a href="#" className="link">Forgot your password?</a>
            <button type="submit" className="btn py-3 btn-primary btn-lg btn-block submit-btn">로그인</button>
            <button type='button' onClick={() => {window.location.href = '/join'}} className="btn mt-3 py-3 btn-light btn-lg btn-block submit-btn" style={{background: 'rgb(223 223 223)'}}>회원가입</button>

            {/* social Login box */}
            <div className="d-flex justify-content-between mt-3 gap-2">
              <a href="http://localhost:9999/oauth2/authorization/kakao">
                <img className="social-login-box" src={kakaoLoginBtn} alt="카카오로그인버튼" />
              </a>
              <a href="http://localhost:9999/oauth2/authorization/naver">
                <img className="social-login-box" src={naverLoginBtn} alt="네이버로그인버튼" />
              </a>
            </div>


          </form>
        </div>

        {/* Overlay */}
        {/* <div className="login-container__overlay" >
          <div className="overlay">
            <div className="overlay__panel overlay--left">
              <div className='overlay-win'/>
              <button onClick={() => setRightPanelActive(false)} type="button" className="btn py-3 btn-primary btn-lg btn-block border-button">로그인</button>
            </div>
            <div className="overlay__panel overlay--right">
              <button onClick={() => setRightPanelActive(true)} type="button" className="btn py-3 btn-primary btn-lg btn-block border-button">회원가입</button>
            </div>
          </div>
        </div> */}
      </div>



      {/* 


      <form className='login-form' onSubmit={handleSubmit}>
        {errMsg !== '' && <div className='errMsg red'> {errMsg} </div>}
        <div>
          <label htmlFor="username_field">Eail:</label>
          <input
            type="text"
            id="username_field"
            name="username_field"
            value={param.username_field}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="password_field">Password:</label>
          <input
            type="password"
            id="password_field"
            name="password_field"
            value={param.password_field}
            onChange={handleChange}
          />
        </div>
        <button type="submit"> shoot </button>
      </form>

      <div>
        <a href="http://localhost:9999/oauth2/authorization/kakao">카카오 로그인 shooooooooooooooooooooooooooooooooooooooot</a>
        <a href="http://localhost:9999/oauth2/authorization/naver">네이버 로그인 shooooooooooooooooooooooooooooooooooooooot</a>
      </div> */}



    </div>

    // <div>
    //   <Dummy />
    //   <h1>Login</h1>
    //   <p>Login Page</p>
    //   <form className='login-form' onSubmit={handleSubmit}>
    //     {errMsg !== '' && <div className='errMsg red'> {errMsg} </div>}
    //     <div>
    //       <label htmlFor="username_field">Eail:</label>
    //       <input
    //         type="text"
    //         id="username_field"
    //         name="username_field"
    //         value={param.username_field}
    //         onChange={handleChange}
    //       />
    //     </div>
    //     <div>
    //       <label htmlFor="password_field">Password:</label>
    //       <input
    //         type="password"
    //         id="password_field"
    //         name="password_field"
    //         value={param.password_field}
    //         onChange={handleChange}
    //       />
    //     </div>
    //     <button type="submit"> shoot </button>
    //   </form>

    //   {/* 카카오 로그인 버튼 */}
    //   <div>
    //     {/* <button onClick={handleKaKaoLogin}>카카오 로그인 shoooot</button> */}
    //     <a href="http://localhost:9999/oauth2/authorization/kakao">카카오 로그인 shooooooooooooooooooooooooooooooooooooooot</a>
    //     <a href="http://localhost:9999/oauth2/authorization/naver">네이버 로그인 shooooooooooooooooooooooooooooooooooooooot</a>
    //   </div>
    // </div>
  )
}

export default Login;