import axios, { AxiosResponse } from "axios";
import api from './api'
import { ReelsParams } from "../types/types";


const ReelsList = (params: ReelsParams) => {
  return api.get('/api/reels/list', { params });
}


const ReelsAPI = { reelsList: ReelsList };

export default ReelsAPI;