import { useEffect } from "react";
import FadeSlider2 from "../../FadeSlider2";
import VisualSlide from "../../VisualSlide";
import { useNavigate } from "react-router-dom";

const Introduce = () => {

  const slides = [VisualSlide];
  const SLIDE_TEXT = "Introduce";
  const navigate = useNavigate()


  useEffect(() => {
    const timer = setTimeout(() => {
      alert('사이트 준비중입니다.');
      navigate('/');
    }, 500);
  
    return () => clearTimeout(timer);
  }, []);
  


  return (
    <div>

      <div className="cmm-container">

        <FadeSlider2 components={slides} text={SLIDE_TEXT} />

        <div className="container mt-5">
          <div className="d-flex justify-content-center align-items-center my-5">
            <span className="col-4 border-bottom mx-2"></span>
            <h1 className="col-4 text-center my-0">Introduce</h1>
            <span className="col-4 border-bottom mx-2"></span>
          </div>
        </div>




      </div>
    </div>




  )

}

export default Introduce;