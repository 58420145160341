import React, { useEffect } from "react";
import Dummy from "../Dummy";
import BoardAPI from '../../apis/BoardAPI';

const Product = () => {
  useEffect(() => {
    
  }, []);

  return (
    <div>
      <h1>Product</h1>
      <p>Product Page</p>
      <Dummy />
    </div>
  );
};

export default Product;
