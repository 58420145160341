import '../styles/scrollArrow.css';

const ScrollArrow = () => {
  return (
    <div className="s1_arrow">
        <div className="scroll-arrow"></div>
        <div className="scroll-arrow"></div>
        <div className="scroll-arrow"></div>
      </div>
  );
}

export default ScrollArrow;