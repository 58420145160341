import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VisualSlide from "../../VisualSlide";
import FadeSlider2 from '../../FadeSlider2';

import BoardAPI from '../../../apis/BoardAPI';
import { BoardItem, BoardSaveForm, initCommunityParams } from '../../../types/types';
import EditorComponent from '../../QuillEditor';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/RootReducer';
import useAuthorization from '../../../hooks/useAuthorization';



const CommunityEditForm = () => {
  const navigate = useNavigate();
  const SLIDE_TEXT = "Community";
  const slides = [VisualSlide];
  const { id } = useParams();
  const [params, setParams] = useState(initCommunityParams);

  //TODO
  const userState = useSelector((state: RootState) => state.user);  
  const handleAuth = useAuthorization();
  
  //updateForm 
  const [injectContent, setInjectContent] = useState("");
  const [editorTitle, setEditorTitle] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [editorImg, setEditorImg] = useState<string[]>([]);


  //랜더링 시 API 호출
  useEffect(() => {
    const param = {
      ...params,
      id: (id !== undefined) ? id : undefined,
    };

    BoardAPI.detail(param).then((res) => {
      //ql-editor 값 할당.
      const editor = document.getElementsByClassName('ql-editor')[1];
      if (editor) setInjectContent(res.data.content)
      //editorTitle
      setEditorTitle(res.data.title)
      })
      .catch((error) => {
        console.log('boardAPI-E: ', error.response?.data?.message);
        //TODO
        // alert('잠시 후에 다시 시도해주세요.')
        // navigate('/COMMUNITY')
      });

  }, []);


  const submitPost = () => {
    if(handleAuth){
      const data: BoardSaveForm = {
        id: (id && id !== '') ? parseInt(id): undefined,
        title: (editorTitle !== '') ? editorTitle : undefined,
        content: (editorContent !== '' && editorContent !== '<p><br></p>') ? editorContent : undefined,
        uploadFiles: (editorImg.length > 0) ? editorImg : undefined,
      };
      BoardAPI.update(data)
        .then((res) => {
          alert('게시글이 수정되었습니다.')
          navigate('/COMMUNITY/'+ res.data, { replace : true })
          //TODO:성공 시 페이지 이동, state 초기화
        }).catch((e) => {
          alert("error 발생: " + e)
        });
    }
  };

    //뒤로가기 버튼클릭
    const prevBtn = () => {
      if((editorTitle || editorContent) && editorContent !== '<p><br></p>') {
        if(window.confirm('내용이 저장되지 않았습니다. 정말 돌아가시겠습니까?')){
          navigate(-1)
        }
      }else{
        navigate(-1)
      }
    }



  return (
    <div>
      <FadeSlider2 components={slides} text={SLIDE_TEXT} />
      {/* <EditorComponent /> */}
      <section className="py-5">
        <div className="container px-4 px-lg-5 my-5">
          <div className="col-md-12">
            {/* <div className="small mb-1">SKU: BST-498</div> */}
            {/* <h1 className="display-5 fw-bolder mb-5">{boardItem?.title}</h1> */}
          </div>
          <div className="container form-container mt-5 mb-5" style={{ maxWidth: '1600px', margin: '0 auto' }}>
            <div className="d-flex justify-content-between mt-2">
              <button type="button" className="btn btn-secondary mb-5" onClick={prevBtn}>뒤로가기</button>
              <button type="button" className="btn btn-primary mb-5" onClick={submitPost}>게시글 저장</button>
            </div>

            <label>제목</label>
            <input id="postTitle"
              className="form-control mb-3"
              onChange={(e) => setEditorTitle(e.target.value)}
              placeholder="게시글 제목을 입력하세요"
              type="text"  value={editorTitle}/>
            <label>내용</label>
            <EditorComponent onContentChange={setEditorContent} onImgChange={setEditorImg} imgs={editorImg} injectContent={injectContent} />
          </div>
        </div>
      </section>

    </div>
  );
};

export default CommunityEditForm;




{/* 
        <div className="row gx-4 gx-lg-5 align-items-center">
            <div className="col-md-6"><img className="card-img-top mb-5 mb-md-0" src="https://dummyimage.com/600x700/dee2e6/6c757d.jpg" alt="..." /></div>
            <div className="col-md-6">
                <div className="small mb-1">SKU: BST-498</div>
                <h1 className="display-5 fw-bolder">Shop item template</h1>
                <div className="fs-5 mb-5">
                    <span className="text-decoration-line-through">$45.00</span>
                    <span>$40.00</span>
                </div>
                <p className="lead">Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium at dolorem quidem modi. Nam sequi consequatur obcaecati excepturi alias magni, accusamus eius blanditiis delectus ipsam minima ea iste laborum vero?</p>
                <div className="d-flex">
                    <input className="form-control text-center me-3" id="inputQuantity" type="num" value="1" />
                    <button className="btn btn-outline-dark flex-shrink-0" type="button">
                        <i className="bi-cart-fill me-1"></i>
                        Add to cart
                    </button>
                </div>
            </div>
        </div>
        */}